import React from "react";
import {Checkbox, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography} from "@mui/material";


export default function Specification({formik}) {

    function handlePayedStatus(checked) {
        formik.setFieldValue('order.paymentStatus', checked ? 1 : 2);
    }
    return (
        <Paper sx={{width: "100%", padding: 2}}>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    <Typography variant={"body1"}>
                        Specification :
                    </Typography>
                </Grid>
                <Grid container item md={12} xs={12}>
                    <Grid container item md={3} xs={6}>
                        <FormControlLabel key="isExchange"
                                          control={<Checkbox key="isExchange" name="order.isExchange"
                                                             checked={formik.values.order.isExchange}
                                                             onChange={(e,checked) => {formik.setFieldValue('order.isExchange', checked ? 1 : 0)}}/>}
                                          label="C'est un Echange"/>
                    </Grid>
                    <Grid container item md={3} xs={6}>
                        <FormControlLabel key="isFragile" control={<Checkbox key="isFragile"
                                                                             checked={formik.values.order.isFragile === true}
                                                                             onClick={(() => formik.setFieldValue('order.isFragile', !formik.values.order.isFragile))}
                                                                             name="order.isFragile"/>}
                                          label="Colis Fragile"/>
                    </Grid>
                    <Grid container item md={3} xs={6}>
                        <FormControlLabel key="paymentStatus"
                                          control={<Checkbox key="paymentStatus" name="order.paymentStatus"
                                                             checked={formik.values.order.paymentStatus === 1 }
                                                             onChange={(e,checked) => handlePayedStatus(checked)}/>} label="Payé"/>
                    </Grid>
                    <Grid container item md={3} xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="order.freeShipping"
                                    checked={formik.values.order.freeShipping === 1}
                                    onChange={(e,v) => formik.setFieldValue('order.freeShipping', e.target.checked   ? 1 : 0)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Livraison gratuite"
                        />

                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Typography variant={"body1"}>
                        la taille du paquet :
                    </Typography>
                    <Grid container item xs={12}>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel key="small" value="1"
                                                  onClick={() => formik.setFieldValue('order.packageSize', 1)}
                                                  control={<Radio
                                                      checked={formik.values.order.packageSize === 1}/>}
                                                  label="Petit"/>
                                <FormControlLabel key="middle" value="2"
                                                  onClick={() => formik.setFieldValue('order.packageSize', 2)}
                                                  control={<Radio/>} label="Moyen"/>
                                <FormControlLabel key="big" value="3"
                                                  onClick={() => formik.setFieldValue('order.packageSize', 3)}
                                                  control={<Radio/>} label="Grand"/>
                                <FormControlLabel
                                    key="hello"
                                    value="4" onClick={() => formik.setFieldValue('order.packageSize', 4)}
                                    control={<Radio/>}
                                    label="Extra large"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}