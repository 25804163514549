import React from "react";
import { Stack, Tooltip } from "@mui/material"; // @mui/material v5.0.6
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

export default function OrderInfos({ order, direction = "row" }) {
    return (
        <Stack
            direction={direction}
            spacing={1.5}
            alignItems="center"
            justifyContent="center"
            sx={{
                p: 0.5,
                bgcolor: "grey.100",
                borderRadius: 1,
                "& .MuiSvgIcon-root": {
                    fontSize: { xs: 18, sm: 20 }, // Responsive icon size
                    transition: "color 0.2s ease-in-out",
                },
            }}
        >
            {/* Free Delivery Status */}
            <Tooltip title={order.free_delivery ? "Livraison gratuite" : "Livraison payante"}>
                <LocalShippingIcon
                    sx={{ cursor: "pointer" }}
                    color={order.free_delivery ? "primary" : "success"}
                />
            </Tooltip>

            {/* Payment Status */}
            <Tooltip title={order.paymentStatus === 2 ? "Recevoir paiement" : "Payé"}>
                {order.paymentStatus === 2 ? (
                    <MoneyOffIcon
                        sx={{ cursor: "pointer", color: "error.main" }}
                    />
                ) : (
                    <MonetizationOnIcon
                        sx={{ cursor: "pointer", color: "success.main" }}
                    />
                )}
            </Tooltip>

            {/* Money Received Status */}
            <Tooltip title={order.moneyReceived === 0 ? "Argent non collecté" : "Argent collecté"}>
                <CreditScoreIcon
                    color={order.moneyReceived === 0 ? "primary" : "secondary"}
                    sx={{ cursor: "pointer" }}
                />
            </Tooltip>

            {/* Exchange Status */}
            {order.isExchange === 1 && (
                <Tooltip title="C'est un échange">
                    <ChangeCircleIcon
                        sx={{ cursor: "pointer" }}
                        color="info"
                    />
                </Tooltip>
            )}
        </Stack>
    );
}