import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    MenuItem,
    Select,
    Typography,
    Box,
    Card,
    CardContent,
    Divider,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack, Button,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AlertsContext from "../../../../context/alertsContext";
import UserContext from "../../../../context/userContext";
import OrderContext from "../../../../context/OrderContext";
import apiCall from "../../../../api/axios_config";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OrderSummary = ({ order }) => {
    const theme = useTheme();

    return (
        <Card sx={{ mb: 2, boxShadow: 1, borderRadius: 2 }}>
            <CardContent>
                {/* Client Information */}
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', color: theme.palette.primary.main }}>
                    Client Information
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Name:</strong> {order.client.fullName}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Phone:</strong> {order.client.phone}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Address:</strong> {order.client.address}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    <strong>Delegation:</strong> {order.client.delegation.name}, {order.client.delegation.gov.name}
                </Typography>

                <Divider sx={{ my: 1 }} />

                {/* Order Details */}
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', color: theme.palette.primary.main }}>
                    Order Details
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Order Number:</strong> {order.commandeNumber}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Delivery Date:</strong> {order.deliveryDate}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Total Amount:</strong> {order.amount} TND
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    <strong>Delivery Price:</strong> {order.delivery_price} TND
                </Typography>

                <Divider sx={{ my: 1 }} />

                {/* Items Table */}
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', color: theme.palette.primary.main }}>
                    Items
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Quantity</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Price (TND)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.product.name}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default function PickUp({ order }) {
    const [open, setOpen] = useState(false);
    const [deliveryCompanies, setDeliveryCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const { setSnackBar, setProgressBar } = useContext(AlertsContext);
    const { accessToken } = useContext(UserContext);
    const { orders, setOrders } = useContext(OrderContext);
    const [activeButton, setActiveButton] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        apiCall(accessToken).get('/delivery_companies/all')
            .then(res => {
                setDeliveryCompanies(res.data);
                // Set the first delivery company as the default selected
                if (res.data.length > 0) {
                    setSelectedCompany(res.data[0].name);
                }
            })
            .catch(() => setSnackBar({ status: true, message: "Erreur lors du chargement des entreprises de livraison", severity: "error" }));
    }, [accessToken, setSnackBar]);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const submitToIntigo = () => {
        setActiveButton(false);
        setProgressBar(true);
        apiCall(accessToken).post('/orders/submit_to_intigo', { orders: [order.id] })
            .then(res => {
                if (res.status === 200) {
                    setSnackBar({ status: true, message: "Envoyée vers Intigo avec succès" });
                    const newList = orders.map(obj => obj.id === order.id ? res.data.orders[0] : obj);
                    setOrders(newList);
                    handleClose();
                }
            })
            .catch(() => setSnackBar({ status: true, message: "Une erreur est survenue, veuillez réessayer plus tard", severity: "error" }))
            .finally(() => {
                setProgressBar(false);
                setActiveButton(true);
            });
    };

    const handleSubmit = () => {
        let updatedOrder = { ...order };
        let data = {};

        if (selectedCompany === 'IntiGo') {
            submitToIntigo();
            return;
        } else if (selectedCompany === 'Sghiri.tn') {
            data = { deliveryStatus: 11, deliveryStatusName: "Sghiri.tn Livraison", phase: "dropOff" };
        } else if (selectedCompany === 'Grafika+') {
            data = { deliveryStatus: 101, deliveryStatusName: "pickUpClient", phase: "dropOff" };
        }

        apiCall(accessToken)
            .post(`/orders/editer/${order.id}`, data)
            .then(() => {
                setSnackBar({ status: true, message: `Commande envoyée vers ${selectedCompany} avec succès`, severity: "success" });
                setOrders(prevOrders => prevOrders.map(o => o.id === order.id ? { ...o, ...data } : o));
            })
            .catch(() => setSnackBar({ status: true, message: "Une erreur est survenue lors de la modification de la commande", severity: "error" }))
            .finally(() => {
                setProgressBar(false);
                handleClose();
            });
    };

    return (
        <Stack alignItems="center" spacing={0.5}>
            <IconButton
                onClick={handleClickOpen}
                disabled={!activeButton}
                sx={{
                    bgcolor: "primary.light",
                    "&:hover": { bgcolor: "primary.main" },
                    color: "white",
                    width: 36,
                    height: 36,
                }}
            >
                <DoubleArrowIcon fontSize="small" />
            </IconButton>
            <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontSize: { xs: 10, sm: 12 } }}
            >
                PickUp
            </Typography>

            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}>
                    Choisissez une entreprise de livraison
                </DialogTitle>
                <DialogContent sx={{ mt: 2 }}>
                    {/* Delivery Company Selection */}
                    <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: theme.palette.primary.main }}>
                        Sélectionnez une entreprise de livraison
                    </Typography>
                    <Select
                        fullWidth
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        sx={{ mt: 1, mb: 2 }}
                    >
                        {deliveryCompanies.map(company => (
                            <MenuItem key={company.id} value={company.name}>{company.name}</MenuItem>
                        ))}
                    </Select>

                    {/* Order Summary */}
                    <OrderSummary order={order} />
                </DialogContent>
                <DialogActions sx={{ p: 2, backgroundColor: theme.palette.background.default }}>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Annuler
                    </Button>
                    <Button onClick={handleSubmit} endIcon={<DoubleArrowIcon />} disabled={!activeButton} variant="contained" color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}