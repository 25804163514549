import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import DeliveryStatus from "./delivery_status";
import Grid from "@mui/material/Grid";
import moment from "moment";
import OrderInfos from "./infos";
import OrderActions from "../actions";
import SelectOrder from "./selectOrder";

export default function OrderAccordion({order}) {

    return (
            <Grid container item xs={12}>
                <Accordion  expanded={false} sx={{width : "100%"}}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon onClick={() => toggleAcordion()} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container item xs={12} spacing={2}>
                            <Grid container item xs={1}>
                                <SelectOrder order={order}/>
                            </Grid>
                            <Grid container item xs={2}>
                                <Stack alignItems="center" justifyContent="center">
                                    <Typography fontWeight={500} fontSize={15}> N°: {order.commandeNumber}</Typography>
                                    <Typography
                                        fontSize={11}
                                        fontWeight={400}
                                        color="primary"
                                    >
                                        {moment(order.created_at).format("D-MM-Y")}
                                    </Typography>
                                    <Typography
                                        fontSize={11}
                                        fontWeight={400}
                                        color="primary"
                                    >
                                        {moment(order.created_at).format("HH:mm")}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid container item xs={3} justifyContent="center">
                                <Stack alignItems="center" justifyContent="center">
                                    <Typography noWrap variant="body1" fontWeight={600}>{order.client.fullName}</Typography>
                                    <Typography noWrap variant="body1" color="primary">{order.client.phone}  {order.client.phoneTwo && "- " + order.client.phoneTwo}</Typography>
                                    <Typography noWrap variant="body1">{order.amount} DT</Typography>
                                </Stack>
                            </Grid>
                            <Grid container item xs={2} justifyContent="center">
                                <OrderInfos order={order} direction="column"/>
                            </Grid>
                            <Grid container item xs={4} justifyContent="center" alignItems="center">
                                <Stack spacing={2} alignItems="center">
                                    <DeliveryStatus order={order}/>
                                    <OrderActions order={order}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
            </Grid>
    );
}
