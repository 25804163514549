import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import CreatePackModal from "../../../modal/create-pack-modal";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import {apiUrl, managerApiUrl} from "../../../common";
import ProductsList from "./list";
import ProductCard from "./productCard";
import {routesName} from "../../../router/router";
import AlertsContext from "../../../context/alertsContext";




export default function PacksPage(){
    const {accessToken} = useContext(UserContext);
    const [packs,setPacks] = useState();
    const {setProgressBar,setSnackBar} = useContext(AlertsContext);

    const deleteItem = (item) => {
        setProgressBar(true);
        apiCall(accessToken)
            .delete(managerApiUrl("/packs/delete/" + item.id))
            .then((res) => {

                setProgressBar(false);
                setSnackBar({
                    message : "le pack " + item.name + " supprimer avec succée",
                    status : true,
                    severity : "success"
                    }
                );
                setPacks((prevState) => prevState.filter((s) => s.id !== item.id));
            })
            .catch((e) => {
                setProgressBar(false);
                setSnackBar({
                    message : "Une erreur est servenue lors de la suppression de la pack " + item.name,
                    status : true,
                    severity : "error"
                    }
                );
            });
    };
    useEffect(() => {
       apiCall(accessToken)
           .get(managerApiUrl("/packs/list"))
           .then((res) => {
               setPacks(res.data.packs);
           })
    },[]);
    return(
        <Grid container item xs={12} spacing={2}>
            <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
            >
                <CreatePackModal setPacks={setPacks}/>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                {
                    packs && packs.map((pack) => (
                        <Grid container item xs={12} sm={6} md={4} lg={3} >
                            {packs && <ProductCard
                                item={pack}
                                image={pack.main_image}
                                editLink={routesName.products.packEdit.path(pack.id)}
                                deleteLink = {deleteItem}
                            />}
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    )
}
