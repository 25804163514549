import React, {useEffect, useState} from "react";
import './App.css';
import {BrowserRouter as Router} from "react-router-dom";
import SghiriRouter from "./router/router";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import UserInfos from "./router/middleware/userInfos";
import AlertsContext from "./context/alertsContext";
import SideBarContext from "./context/sideBarContext";
import SnackBar from "./component/common/snackBar";
import {theme} from "./themev2"
import moment from 'moment';
import localization from 'moment/locale/fr';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

moment.updateLocale('fr', localization);

function App() {
    const [isSidebarOpen, setSidebar] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [snackBar, setSnackBar] = useState({status: false, message: "", severity: "success"});
    const [isOnline,setIsOnline] = useState(navigator.online);


    useEffect(() => {
        function onlineHandler() {
            setIsOnline(true);
        }

        function offlineHandler() {
            setIsOnline(false);
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);


        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, []);


    function setSidebarOpen(value) {
        setSidebar(value)
    }

    function setLoadingVal(value) {
        setLoading(value);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>

            <ThemeProvider theme={theme}>
                <AlertsContext.Provider
                    value={{progressBar, setProgressBar, snackBar, setSnackBar, isLoading, setLoadingVal}}>
                    <SideBarContext.Provider value={{isSidebarOpen, setSidebarOpen}}>
                            <Router>
                                <UserInfos>
                                    <CssBaseline/>
                                    <SnackBar/>
                                    <SghiriRouter/>
                                </UserInfos>
                            </Router>
                    </SideBarContext.Provider>
                </AlertsContext.Provider>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
