import React, {useContext} from "react";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {AppBar, Avatar, Box, IconButton, Toolbar, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from "@mui/material/Grid";
import ProgressBar from "../../component/common/progressBar";
import {Logout} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {stringAvatar} from "../../helpers/functions";
import UserContext from "../../context/userContext";
import {hashedKey} from "../../helpers/hashedKey";
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";


const DashboardNavbarRoot = styled(AppBar)(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
    const {onSidebarOpen, ...other} = props;
    const {userInfos,logoutUser} = useContext(UserContext);
    const history = useHistory();
    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 280
                    },
                    width: {
                        lg: 'calc(100% - 280px)'
                    }
                }}
                {...other}>
                <ProgressBar/>

                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2,
                        backgroundColor: "neutral.900",
                        color: "white !important"
                    }}
                >
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none'
                            },
                            color: "white"
                        }}
                    >
                        <MenuIcon fontSize="small"/>
                    </IconButton>
                    <Grid container item sx={{display: {lg: "none"}}}>
                        <img alt="red" src={"/red.png"}/>
                    </Grid>
                    <Box sx={{flexGrow: 1}}/>
                    <Tooltip title="Contacts">
                        <IconButton sx={{ml: 1}}>
                            {/*<UsersIcon fontSize="small" />*/}
                        </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Notifications">*/}
                    {/*    <IconButton sx={{ ml: 1, color : "white" }}>*/}
                    {/*        <Badge*/}
                    {/*            badgeContent={4}*/}
                    {/*            color={"white"}*/}
                    {/*            variant="dot"*/}
                    {/*        >*/}
                    {/*        </Badge>*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                    <Tooltip title={"Se déconnecter"}>
                        <Button
                            variant="contained"
                            size="small"
                            endIcon={<Logout />}
                            onClick={async () => {
                                await localStorage.removeItem(hashedKey('userData'));
                                await logoutUser();
                                await history.push('/login');
                            }}
                        >
                            <Typography noWrap>Se déconnecter</Typography>
                        </Button>
                    </Tooltip>
                    {/*<Avatar*/}
                    {/*    {...stringAvatar(userInfos.name)}*/}
                    {/*    sx={{*/}
                    {/*        height: 40,*/}
                    {/*        width: 40,*/}
                    {/*        ml: 1*/}
                    {/*    }}*/}
                    {/*    src="/static/images/avatars/avatar_1.png"*/}
                    {/*>*/}

                    {/*</Avatar>*/}

                </Toolbar>
            </DashboardNavbarRoot>
        </>
    );
};

DashboardNavbar.propTypes = {
    onSidebarOpen: PropTypes.func
};
