import React, {useContext} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import PrivateRoute from "./middleware/privateRoute";
import PublicRoute from "./middleware/publicRoute";
import CreateProduct from "../pages/manager/products/create";
import ProductsList from "../pages/manager/products/list";
import CreateOrder from "../pages/manager/orders/create";
import NoAccess from "../pages/user/noaccess";
import Dashboard from "../pages/dashboard/home";
import OrderList from "../pages/manager/orders/list";
import EditOrder from "../pages/manager/orders/edit";
import OrderPDF from "../pages/manager/orders/pdf/orderPDF";
import PageNotFound from "../pages/404";
import {Switch} from "react-router";
import UserContext from "../context/userContext";
import StoresIndex from "../pages/manager/stores/stores_index";
import SingleStore from "../pages/manager/stores/single_store";
import EditProduct from "../pages/manager/products/edit";
import LoginForm from "../pages/user/loginForm";
import RestrictedToAdminRoute from "./middleware/restrictedToAdminRoute";
import FacturePDF from "../pages/manager/orders/pdf/facturePDF";
import ProductStats from "../pages/manager/products/stats";
import PacksPage from "../pages/manager/products/packs";
import EditPack from "../pages/manager/products/edit-pack";
import RemisePage from "../pages/dashboard/remise-page";
import Finance from "../pages/dashboard/finance";
import OrderItems from "../pages/manager/orders/OrderItems";



export const routesName = {
    dashboard : {
        index : "/",
        remise : {
            path : "/remise",
            component : RemisePage,
        },
        finance : {
            path : "/finance",
            component : Finance,
        }
    },
    products : {
        index: "/products",
        create: "/products/create",
        //TODO : Rectification for the link ' add prefix /products'
        edit: (id) => `/products/edit/${id}`,
        stats : {
            path : "/products/stats",
            component : ProductStats,
        },
        packs : {
            path : "/products/packs",
            component : PacksPage,
        },
        packEdit : {
            path : (id) => `/products/pack/edit/${id}`,
            component : EditPack,
        }
    },
    orders : {
        index : {
            path : "/orders/list",
            component : OrderList
        },
        create : "/orders/nouveau",
        edit : "/orders/edit/:id",
        pdf : {
            path : (id) => `/orders/pdf/${id}`,
            component : OrderPDF
        },
        facture : {
            path : (id) => `/orders/facture/${id}`,
            component : FacturePDF
        },
        orderItems : {
            path : (ids) => `/orders/orderItems/${ids}`,
            component : OrderItems
        }
    },
    stores : {
        index : {
            path : `/stores`,
            component : <StoresIndex/>
        },
        create : "/stores/create",
        single : {
            path : (id) => `/stores/${id}`,
            component : <SingleStore/>
        }
    }
};
function SghiriRouter() {
    const {isAuth} = true;
    // const {isAuth} = useContext(UserContext);
    return (
            <Router>
                <Switch>
                    {/*Manager Dashboard*/}
                    <PrivateRoute isAuth={isAuth} component={Dashboard} path={routesName.dashboard.index} exact/>
                    <PrivateRoute isAuth={isAuth} component={routesName.dashboard.finance.component} path={routesName.dashboard.finance.path}/>
                    <PrivateRoute isAuth={isAuth} component={routesName.dashboard.remise.component} path={routesName.dashboard.remise.path}/>

                    {/*Manager Products*/}
                    <RestrictedToAdminRoute component={ProductsList} exact path={routesName.products.index}/>
                    <RestrictedToAdminRoute isAuth={isAuth} component={CreateProduct} path={routesName.products.create}/>
                    <RestrictedToAdminRoute isAuth={isAuth} component={EditProduct} path={routesName.products.edit(":id")}/>
                    <RestrictedToAdminRoute isAuth={isAuth} component={routesName.products.stats.component} path={routesName.products.stats.path}/>
                    <RestrictedToAdminRoute isAuth={isAuth} component={routesName.products.packs.component} path={routesName.products.packs.path}/>
                    <RestrictedToAdminRoute isAuth={isAuth} component={routesName.products.packEdit.component} path={routesName.products.packEdit.path(':id')}/>

                    {/*Manager Orders*/}

                    {/*<RestrictedToAdminRoute userRole={userRole} isAuth={isAuth} component={OrderIndex} path={routesName.orders.index}/>*/}
                    <PrivateRoute  component={routesName.orders.index.component} path={routesName.orders.index.path}/>
                    <PrivateRoute  component={EditOrder} path={routesName.orders.edit}/>
                    <PrivateRoute  component={CreateOrder} path={routesName.orders.create}/>
                    <PrivateRoute  component={routesName.orders.pdf.component} withLayout={false} path={routesName.orders.pdf.path(':id')}/>
                    <PrivateRoute  component={routesName.orders.facture.component} withLayout={false} path={routesName.orders.facture.path(':id')}/>
                    <PrivateRoute  component={routesName.orders.orderItems.component} withLayout={false} path={routesName.orders.orderItems.path(':id')}/>
                    {/*Manager Stores*/}
                    <PrivateRoute  component={StoresIndex}  path={routesName.stores.index.path} exact />
                    <PrivateRoute  component={SingleStore}  path={routesName.stores.single.path(':id')}/>


                    <PrivateRoute restricted={false} withLayout={false}  component={NoAccess} path={"/no_access"}/>
                    <PrivateRoute restricted={false} withLayout={false}  component={PageNotFound} path={"/404"}/>


                    {/*Login Page*/}
                    <PublicRoute restricted={true}  component={LoginForm} path={"/login"}/>
                    <PublicRoute restricted={false}  component={PageNotFound} path={"*"}/>
                    <PublicRoute restricted={false}  component={<p></p>} path={"/temp"}/>
                </Switch>
            </Router>
    );
}

export default SghiriRouter;
