import React, { useContext, useState } from "react";
import {
    Chip,
    Typography,
    IconButton,
    Box,
    Stack,
    CircularProgress,
    Menu,
    MenuItem,
} from "@mui/material";
import { updateOrder } from "../../../../../api/order";
import UserContext from "../../../../../context/userContext";
import OrderContext from "../../../../../context/OrderContext";
import AlertsContext from "../../../../../context/alertsContext";
import moment from "moment";
import CallIcon from "@mui/icons-material/Call";
import SmsIcon from "@mui/icons-material/Sms";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneIcon from "@mui/icons-material/Done";
import ReplayIcon from "@mui/icons-material/Replay";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PickUp from "../../pickup/PickUp";

export default function DeliveryStatus({ order }) {
    const { accessToken } = useContext(UserContext);
    const { setOrders } = useContext(OrderContext);
    const { setProgressBar } = useContext(AlertsContext);
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); // For menu positioning

    const getDeliveryChipStyles = (status) => {
        const styles = {
            1: { color: "info", variant: "outlined" },
            2: { color: "info", variant: "outlined" },
            6: { color: "success", variant: "outlined" },
            10: { color: "warning", variant: "outlined" },
            11: { color: "warning", variant: "outlined" }, // Added for clarity
            14: { color: "error", variant: "filled" },
            15: { color: "warning", variant: "filled" },
            16: { color: "error", variant: "filled" },
            17: { color: "error", variant: "filled" },
            101: { color: "warning", variant: "outlined" }, // Added for clarity
        };
        return styles[status] || { color: "info", variant: "filled" };
    };

    const handleOrderAction = async (action, status = null, statusName = null) => {
        if (isLoading) return;

        setIsLoading(true);
        setProgressBar(true);

        try {
            let payload = {};
            const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

            switch (action) {
                case "call":
                    payload = { phase: "call1", phaseTime: currentTime };
                    break;
                case "sms":
                    payload = { phase: "smsSent", phaseTime: currentTime };
                    break;
                case "confirm":
                    payload = { phase: "confirmed", phaseTime: currentTime };
                    break;
                case "reset":
                    payload = {
                        phase: null,
                        phaseTime: null,
                        nid: null,
                        deliveryStatus: 0,
                        deliveryStatusName: null,
                    };
                    break;
                case "updateStatus":
                    payload = {
                        deliveryStatus: status,
                        deliveryStatusName: statusName,
                        phaseTime: currentTime, // Update timestamp
                    };
                    break;
                default:
                    throw new Error("Unknown action");
            }

            const response = await updateOrder(accessToken, order.id, payload);
            const updatedOrder = response.data.order;

            setOrders((prev) =>
                prev.map((o) => (o.id === order.id ? updatedOrder : o))
            );

            if (action === "call") {
                window.location.href = `tel:${order.client.phone}`;
            } else if (action === "sms") {
                const items = order.items
                    ?.map((item) => `${item.quantity}x ${item.product.name}`)
                    .join("\n") || "غير محدد";
                const amount = order.amount ? `${order.amount} د.ت` : "غير محدد";
                const smsMessage = `مرحبا بيك في Sghiri.tn!\nلقد حاولنا الاتصال بك بخصوص طلبك:\n\n${items}\n\nالمجموع: ${amount}\n\nيمكنك الاتصال بنا مرة أخرى أو إرسال رسالة أو التواصل عبر الماسنجر: m.me/sghiri.tn`;
                const encodedMessage = encodeURIComponent(smsMessage);
                window.location.href = `sms:${order.client.phone}?body=${encodedMessage}`;
            }
        } catch (error) {
            console.error(`Error during ${action}:`, error);
        } finally {
            setIsLoading(false);
            setProgressBar(false);
            if (action === "updateStatus") setAnchorEl(null); // Close menu
        }
    };

    const shouldShowCallOrSms = [null, "call1"].includes(order.phase);
    const chipStyles = getDeliveryChipStyles(order.deliveryStatus);

    const formatPhaseTime = (phase, phaseTime) => {
        if (!phaseTime) return null;

        const formattedTime = moment(phaseTime).format("D-MM-YY, HH:mm");
        switch (phase) {
            case "call1":
                return `Call on ${formattedTime}`;
            case "smsSent":
                return `SMS sent on ${formattedTime}`;
            case "confirmed":
                return `Confirmed on ${formattedTime}`;
            default:
                return null;
        }
    };

    // Menu handlers
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleStatusChange = (status, statusName) => {
        handleOrderAction("updateStatus", status, statusName);
    };

    const isStatusEditable = [11, 101].includes(order.deliveryStatus);

    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                width: "100%",
                padding: { xs: 0.5, sm: 1 },
                flexWrap: "wrap",
                gap: 1,
            }}
        >
            {isLoading && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={18} />
                </Box>
            )}

            {!isLoading && shouldShowCallOrSms && (
                <Stack alignItems="center" spacing={0.5}>
                    <IconButton
                        onClick={() =>
                            handleOrderAction(order.phase === null ? "call" : "sms")
                        }
                        disabled={isLoading}
                        sx={{
                            bgcolor: "primary.light",
                            "&:hover": { bgcolor: "primary.main" },
                            color: "white",
                            width: { xs: 30, sm: 36 },
                            height: { xs: 30, sm: 36 },
                        }}
                    >
                        {order.phase === null ? (
                            <CallIcon fontSize="small" />
                        ) : (
                            <SmsIcon fontSize="small" />
                        )}
                    </IconButton>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontSize: { xs: 10, sm: 12 } }}
                    >
                        {order.phase === null ? "Call" : "SMS"}
                    </Typography>
                </Stack>
            )}

            {order.phase === "smsSent" && !isLoading && (
                <Chip
                    label="SMS Sent"
                    icon={<DoneIcon fontSize="small" />}
                    color="success"
                    variant="outlined"
                    sx={{ fontSize: { xs: 10, sm: 12 }, px: 1 }}
                />
            )}

            {order.phase === "confirmed" && !isLoading && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PickUp order={order} />
                </Box>
            )}

            {/* Delivery Status: Chip or Button */}
            {order.deliveryStatusName && !isLoading && (
                <>
                    {isStatusEditable ? (
                        <>
                            <IconButton
                                onClick={handleMenuOpen}
                                sx={{
                                    bgcolor: chipStyles.color + ".light",
                                    "&:hover": { bgcolor: chipStyles.color + ".main" },
                                    color: "white",
                                    width: { xs: 30, sm: 36 },
                                    height: { xs: 30, sm: 36 },
                                }}
                            >
                                <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    onClick={() => handleStatusChange(6, "Delivered")}
                                    sx={{ fontSize: { xs: 12, sm: 14 } }}
                                >
                                    Delivered
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleStatusChange(14, "Returned")}
                                    sx={{ fontSize: { xs: 12, sm: 14 } }}
                                >
                                    Returned
                                </MenuItem>
                            </Menu>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{ fontSize: { xs: 10, sm: 12 }, ml: 1 }}
                            >
                                {order.deliveryStatusName}
                            </Typography>
                        </>
                    ) : (
                        <Chip
                            label={order.deliveryStatusName}
                            color={chipStyles.color}
                            variant={chipStyles.variant}
                            sx={{ fontSize: { xs: 10, sm: 12 }, px: 1 }}
                        />
                    )}
                </>
            )}

            {order.phase !== "confirmed" && order.phase !== "dropOff" && !isLoading && (
                <Stack alignItems="center" spacing={0.5}>
                    <IconButton
                        onClick={() => handleOrderAction("confirm")}
                        disabled={isLoading}
                        sx={{
                            bgcolor: "success.light",
                            "&:hover": { bgcolor: "success.main" },
                            color: "white",
                            width: { xs: 30, sm: 36 },
                            height: { xs: 30, sm: 36 },
                        }}
                    >
                        <CheckCircleIcon fontSize="small" />
                    </IconButton>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontSize: { xs: 10, sm: 12 } }}
                    >
                        Confirm
                    </Typography>
                </Stack>
            )}

            {!isLoading && (
                <Stack alignItems="center" spacing={0.5}>
                    <IconButton
                        onClick={() => handleOrderAction("reset")}
                        disabled={isLoading}
                        sx={{
                            bgcolor: "warning.light",
                            "&:hover": { bgcolor: "warning.main" },
                            color: "white",
                            width: { xs: 30, sm: 36 },
                            height: { xs: 30, sm: 36 },
                        }}
                    >
                        <ReplayIcon fontSize="small" />
                    </IconButton>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontSize: { xs: 10, sm: 12 } }}
                    >
                        Reset
                    </Typography>
                </Stack>
            )}

            {order.phaseTime && !isLoading && (
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{
                        fontSize: { xs: 10, sm: 12 },
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    {formatPhaseTime(order.phase, order.phaseTime)}
                </Typography>
            )}
        </Stack>
    );
}