import {apiUrl} from "../common";
import axios from "axios";


export async function getAllOrders(accessToken,filter,url){

    let filterUrl = apiUrl + "/manager/orders/all" + (filter ? "?" + filter : "");

    const response = await axios.get(
        url ? url : filterUrl,
        {
            headers : {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken
            }
    })
    return await response.data;
}

export async function getOrder(accessToken,id){

    return await axios.get(
        apiUrl + "/manager/orders/get/" + id,
        {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken
            },
            data : {
                ordersId : id
            }
        }
    );

}


export async function updateOrder(accessToken,id,values){
    return await axios.post(
        apiUrl + "manager/orders/edit/" + id,
        values,
        {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        }
    )
}


export async function addItem(accessToken,values){
    return await  axios.post(
        apiUrl + "/manager/orders/item/add",
        values,
        {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        }
    )
}

export async function deleteItem(accessToken,id) {
    return await axios.delete(
        apiUrl + "/manager/orders/item/delete/"+ id,
        {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        }
    )
}