import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import apiCall, {noAuthApiCall} from "../../../api/axios_config";
import {getIn, useFormik} from "formik";
import AlertsContext from "../../../context/alertsContext";
import UserContext from "../../../context/userContext";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DropZonePreview from "../../../component/dropZone";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import {productsImgUrl} from "../../../common";
import Loader from "../../../component/common/loader";
import Button from "@mui/material/Button";




export default function EditPack(){

    //Context
    const {setProgressBar, setSnackBar} = useContext(AlertsContext);
    const {accessToken} = useContext(UserContext);


    //States
    const [pack , setPack] = useState();
    const [products,setProducts] = useState();
    const [selectedProduct , setSelectedProduct] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);

    //Route Params
    const {id} = useParams();

    //Formik
    const formik = useFormik({
        initialValues : !isLoading && {
            name : pack.name,
            description : pack.description,
            products : [],
            reduction : pack.reduction,
            price : pack.price,
            image : [],
        },
        enableReinitialize : true,
        onSubmit : (values) => {
            // console.log(values);
            setProgressBar(true);
            let formData = new FormData;

            formData.append('name',values.name);
            formData.append('description',values.description);
            for ( let  i =0 ; i < values.products.length; i++){
                formData.append('products[]',values.products[i]);
            }
            formData.append('reduction',values.reduction);
            formData.append('price',values.price);
            formData.append('image',values.image);
            apiCall(accessToken)
                .post('/packs/edit/' + id, formData)
                .then(res => {
                    setProgressBar(false);
                    setSnackBar({
                        status : true,
                        message : "Pack créer avec succès",
                        severity : "success"
                    });
                    setPack(res.data.pack);
                })
                .catch(e=> {
                    setProgressBar(false);
                    setSnackBar({
                        status : true,
                        message : "Une erreur est survenue veuillez réessayer plus tard",
                        severity : "error"
                    });
                });
        }
    });

    //useEffect
    useEffect(() => {
        apiCall(accessToken)
            .get('/products/all')
            .then((res) => setProducts(res.data.products))
            .catch((error) => console.log(error));
        noAuthApiCall('/packs/pack/' + id)
            .get()
            .then((res) => {
                const products = res.data.pack.products;
                setPack(res.data.pack);
                setSelectedProduct(products);
                setIsLoading(false);
                let productsId = [];
                products.map((product) => {
                    productsId.push(product.id)
                });
                formik.setFieldValue('products',productsId);
            })
            .catch((e) => {})
        ;
    },[]);

    const handleProductInputChange = (valueArray) => {
        const productsID = valueArray.map(t => t.id);
        setSelectedProduct(valueArray);
        formik.setFieldValue('products', productsID);

        // calculate pack price
        let totalPrice = 0;
        valueArray.map(v => {
            totalPrice += parseFloat(v.price) - (parseFloat(v.price) / 100) * formik.values.reduction ;
        });
        formik.setFieldValue("price", totalPrice);
    }

    function setFiles(files) {
        formik.setFieldValue('image',files[0]);
    }
    return(
        !isLoading &&
        pack ?
        <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12}>
                <TextField

                    margin="dense"
                    fullWidth
                    required
                    name="name"
                    label="Nom de la pack"
                    variant="outlined"
                    InputProps={{ shrink : false }}
                    value={formik.values.name}
                    defaultValue={formik.values.name}
                    onChange={formik.handleChange}
                    error={getIn(formik.touched, "name") && Boolean(getIn(formik.errors, "name"))}
                    helperText={getIn(formik.touched, "name") && getIn(formik.errors, "name")}

                />
            </Grid>

            <Grid container item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    id="description"
                    label="Description de la pack"
                    type="textarea"
                    variant="outlined"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.errors.description}
                />
            </Grid>
            <Grid container item xs={12}>
                <DropZonePreview setFiles={(files) => setFiles(files)} maxFiles={1}/>
            </Grid>
            <Grid container item xs={12} justifyContent={"center"}>
                <Image height={500} width={"auto"} fit={"fill"} src={productsImgUrl(pack.main_image)}/>
            </Grid>
            <Grid container item xs={12}>
                {products &&
                    <Autocomplete
                        multiple
                        fullWidth
                        name="productsID"
                        options={products}
                        getOptionLabel={(option) => option.name}
                        defaultValue={selectedProduct}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                margin="dense"
                                {...params}
                                label="Produits"
                                placeholder="Sélectionnez un ou plusieurs produits"
                            />
                        )}
                        onChange={(event, value) => handleProductInputChange(value)}
                    />

                }
            </Grid>
            <Grid container item xs={12}>
                <Typography color={'primary'}>{ selectedProduct.length }  products selected</Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                { selectedProduct && selectedProduct.map((product) => (
                    <Grid container item xs={3} spacing={2} justifyContent="center">
                        <Grid container item justifyContent="center" xs={12}>
                            <Image width={"100px"} height={"100px"} fit="contain" src={productsImgUrl(product.images[0].image)}/>
                        </Grid>
                        <Grid container item justifyContent="center" xs={12}>
                            {product.name}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid container item xs={6}>
                <TextField
                    margin="dense"
                    fullWidth
                    required
                    name="reduction"
                    label="Reduction (%)"
                    variant="outlined"
                    value={formik.values.reduction}
                    onChange={(event) => {
                        let prePrice = 0;
                        selectedProduct.map(product => {
                            prePrice += parseFloat(product.price);
                        });
                        const afterReduction = prePrice - ((prePrice/100) * event.target.value);
                        formik.setFieldValue('reduction',event.target.value);
                        formik.setFieldValue('price',afterReduction.toFixed(3));
                    }}
                    error={getIn(formik.touched, "reduction") && Boolean(getIn(formik.errors, "reduction"))}
                    helperText={getIn(formik.touched, "reduction") && getIn(formik.errors, "reduction")}
                />
            </Grid>
            <Grid container item xs={6}>
                <TextField
                    margin="dense"
                    fullWidth
                    required
                    name="price"
                    label="Prix"
                    variant="outlined"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={getIn(formik.touched, "price") && Boolean(getIn(formik.errors, "price"))}
                    helperText={getIn(formik.touched, "price") && getIn(formik.errors, "price")}
                />
            </Grid>
            <Grid container item xs={12}>
                <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={formik.submitForm}
                >
                    Enregistrer !
                </Button>
            </Grid>
        </Grid> : <Loader/>

    )
}
