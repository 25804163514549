import React, { createRef, useContext } from "react";
import { Autocomplete, FormHelperText, Grid, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SyncIcon from '@mui/icons-material/Sync';
import apiCall from "../../../../api/axios_config";
import UserContext from "../../../../context/userContext";

export default function Items({
                                  formik,
                                  currentQte,
                                  products,
                                  setCurrentQte,
                                  items,
                                  setItems,
                                  currentProduct,
                                  setCurrentProduct,
                                  itemsError,
                                  setProducts
                              }) {
    const autoC = createRef(null);
    const { accessToken, userInfos } = useContext(UserContext);

    async function handleItems() {
        const newItem = {
            product_id: currentProduct.product_id,
            product: currentProduct,
            quantity: parseInt(currentQte),
        };

        if (items === null) {
            setItems([newItem]);
        } else {
            const find = items.find((i) => i.product.id === newItem.product.id);
            if (!find) {
                setItems((prev) => [...prev, newItem]);
            }
        }
    }

    const handleQuantityChange = (index, newQuantity) => {
        const updatedItems = [...items];
        updatedItems[index].quantity = newQuantity;
        setItems(updatedItems);
    };

    return (
        <Paper sx={{ width: "100%", padding: 2 }}>
            <Grid container item spacing={2} xs={12}>
                <Grid container item xs={12}>
                    <Typography fontWeight={"bold"}>Produits :</Typography>
                </Grid>

                {/* Quantity Input */}
                <Grid container item xs={2}>
                    <TextField
                        fullWidth
                        label="QTE"
                        value={currentQte}
                        variant="outlined"
                        onChange={(e) => setCurrentQte(e.target.value || 0)}
                    />
                </Grid>

                {/* Product Autocomplete */}
                {products && (
                    <Grid container item xs={8}>
                        <Autocomplete
                            disabled={currentQte < 1}
                            fullWidth
                            id="selectProduct"
                            options={products}
                            ref={autoC}
                            getOptionLabel={(o) => o.name}
                            renderOption={(props, option) => (
                                <Grid key={`product-${option.id}`} container item xs={12} {...props}>
                                    <Grid container item xs={8}>
                                        {option.name}
                                    </Grid>
                                    <Grid container item justifyContent="center" xs={4}>
                                        <Typography
                                            sx={{
                                                color: option.quantity - option.sales >= 20 ? "green" : "red",
                                            }}
                                        >
                                            Disponible : {option.quantity - option.sales}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            getOptionDisabled={(option) =>
                                option.quantity - option.sales === 0 ||
                                currentQte > option.quantity - option.sales ||
                                items?.find((i) => i.product_id === option.product_id) !== undefined
                            }
                            onChange={(e, v) => v && setCurrentProduct(v)}
                            renderInput={(params) => <TextField {...params} label="Produit" />}
                        />
                    </Grid>
                )}

                {/* Buttons for Adding Items and Syncing Stock */}
                <Grid container item alignItems="center" xs={2}>
                    <AddBoxIcon
                        sx={{
                            fontSize: 35,
                            cursor: "pointer",
                            "&:hover": { color: (theme) => theme.palette.primary.main },
                        }}
                        onClick={() => {
                            handleItems();
                            const ele = autoC.current.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
                            if (ele) ele.click();
                        }}
                    />
                    <SyncIcon
                        sx={{
                            fontSize: 35,
                            cursor: "pointer",
                            "&:hover": { color: (theme) => theme.palette.primary.main },
                        }}
                        onClick={() => {
                            apiCall(accessToken).get('/stores/get_stock/' + userInfos.store.id).then((res) => setProducts(res.data.stock));
                        }}
                    />
                </Grid>
            </Grid>

            {/*/!* Display Added Items with Editable Quantities *!/*/}
            {/*<Grid container item xs={12} spacing={2}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Typography fontWeight="bold">Items Ajoutés :</Typography>*/}
            {/*    </Grid>*/}
            {/*    {items?.map((item, index) => (*/}
            {/*        <Grid container item xs={12} key={item.product_id}>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <Typography>{item.product.name}</Typography>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <TextField*/}
            {/*                    label="Quantité"*/}
            {/*                    type="number"*/}
            {/*                    value={item.quantity}*/}
            {/*                    onChange={(e) => {*/}
            {/*                        const updatedQuantity = parseInt(e.target.value) || 1;*/}
            {/*                        handleQuantityChange(index, updatedQuantity);*/}
            {/*                    }}*/}
            {/*                    inputProps={{ min: 1 }}*/}
            {/*                    fullWidth*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <Typography>{item.product.price * item.quantity} TND</Typography>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    ))}*/}
            {/*</Grid>*/}

            {/* Error message if no items are selected */}
            {itemsError && <FormHelperText error>{itemsError}</FormHelperText>}
        </Paper>
    );
}
