import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import DialogTitle from '@mui/material/DialogTitle';
import {getIn, useFormik} from "formik";
import Grid from "@mui/material/Grid";
import {useContext, useEffect, useState} from "react";
import apiCall from "../api/axios_config";
import UserContext from "../context/userContext";
import Autocomplete from "@mui/material/Autocomplete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Image from "mui-image";
import {apiUrl, productsImgUrl} from "../common";
import Typography from "@mui/material/Typography";
import DropZonePreview from "../component/dropZone";
import AlertsContext from "../context/alertsContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CreatePackModal({setPacks}) {

    const {accessToken} = useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const [products,setProducts] = React.useState();
    const [selectedProduct , setSelectedProduct] = React.useState([]);
    const [totalPrice,setTotalPrice] = React.useState();
    const {setProgressBar,setSnackBar} = useContext(AlertsContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.setFieldValue('products', []);
        setSelectedProduct([]);
    };

    const formik = useFormik({
        initialValues : {
            name : "",
            description : "",
            products : [],
            reduction : 0,
            price : 0,
            image : [],
        },
        onSubmit : (values) => {

            setProgressBar(true);
            let formData = new FormData;

            formData.append('name',values.name);
            formData.append('description',values.description);
            for ( let  i =0 ; i < values.products.length; i++){
                formData.append('products[]',values.products[i
                    ]);
            }
            formData.append('reduction',values.reduction);
            formData.append('price',values.price);
            formData.append('image',values.image);
            apiCall(accessToken)
                .post('/packs/create', formData)
                .then(res => {
                    setProgressBar(false);
                    handleClose();
                    setSnackBar({
                        status : true,
                        message : "Pack créer avec succès",
                        severity : "success"
                    });
                    setPacks((prevState) => [res.data.pack,...prevState]);
                })
                .catch(e=> {
                    setProgressBar(false);
                    setSnackBar({
                        status : true,
                        message : "Une erreur est survenue veuillez réessayer plus tard",
                        severity : "error"
                    });
                });
        }
    });

    useEffect(() => {
        apiCall(accessToken)
            .get('/products/all')
            .then((res) => setProducts(res.data.products))
            .catch((error) => console.log(error));

    },[]);

    const handleProductInputChange = (valueArray) => {
        const productsID = valueArray.map(t => t.id);
        setSelectedProduct(valueArray);
        formik.setFieldValue('products', productsID);

        // calculate pack price
        let totalPrice = 0;
        valueArray.map(v => {
           totalPrice += parseFloat(v.price) - (parseFloat(v.price) / 100) * formik.values.reduction ;
        });
        formik.setFieldValue("price", totalPrice);
    }

    function setFiles(files) {
        formik.setFieldValue('image',files[0]);
    }
    return (
        <div>
            <Button variant="outlined" startIcon={<AddCircleIcon/>} color={"secondary"} onClick={handleClickOpen}>
                Ajouter
            </Button>
            <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
                <DialogTitle>Ajouter un nouveau pack</DialogTitle>
                <DialogContent >
                    <Grid container item xs={12} spacing={2}>
                        <Grid container item xs={12}>
                            <TextField

                                margin="dense"
                                fullWidth
                                required
                                name="name"
                                label="Nom de la pack"
                                variant="outlined"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={getIn(formik.touched, "name") && Boolean(getIn(formik.errors, "name"))}
                                helperText={getIn(formik.touched, "name") && getIn(formik.errors, "name")}
                            />
                        </Grid>

                        <Grid container item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                id="description"
                                label="Description de la pack"
                                type="textarea"
                                variant="outlined"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.errors.description}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <DropZonePreview setFiles={(files) => setFiles(files)} maxFiles={1}/>
                        </Grid>
                        <Grid container item xs={12}>
                            {products &&
                                <Autocomplete

                                    multiple
                                    fullWidth
                                    name="productsID"
                                    options={products}
                                    getOptionLabel={(option) => option.name}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Produits"
                                            placeholder="Sélectionnez un ou plusieurs produits"
                                        />
                                    )}
                                    onChange={(event, value) => handleProductInputChange(value)}
                                />

                            }
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography color={'primary'}>{ selectedProduct.length }  products selected</Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            { selectedProduct && selectedProduct.map((product) => (
                                <Grid container item xs={3} spacing={2}>
                                    <Grid container item xs={12}>
                                        <Image width={"100px"} height={"100px"} fit="contain" src={productsImgUrl(product.images[0].image)}/>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        {product.name}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container item xs={6}>
                            <TextField
                                margin="dense"
                                fullWidth
                                required
                                name="reduction"
                                label="Reduction (%)"
                                variant="outlined"
                                value={formik.values.reduction}
                                onChange={(event) => {
                                    let prePrice = 0;
                                    selectedProduct.map(product => {
                                        prePrice += parseFloat(product.price);
                                    });
                                    const afterReduction = prePrice - ((prePrice/100) * event.target.value);
                                    formik.setFieldValue('reduction',event.target.value);
                                    formik.setFieldValue('price',afterReduction.toFixed(3));
                                }}
                                error={getIn(formik.touched, "reduction") && Boolean(getIn(formik.errors, "reduction"))}
                                helperText={getIn(formik.touched, "reduction") && getIn(formik.errors, "reduction")}
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            <TextField
                                margin="dense"
                                fullWidth
                                required
                                name="price"
                                label="Prix"
                                variant="outlined"
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                error={getIn(formik.touched, "price") && Boolean(getIn(formik.errors, "price"))}
                                helperText={getIn(formik.touched, "price") && getIn(formik.errors, "price")}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} startIcon={<ArrowBackIcon/>} onClick={handleClose}>Cancel</Button>
                    <Button variant={"contained"} color={"secondary"} endIcon={<LibraryAddIcon/>} onClick={formik.submitForm}>Ajouter</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
