import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Collapse from '@mui/material/Collapse';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {NavLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {routesName} from "../router/router";
import BackpackIcon from '@mui/icons-material/Backpack';
import {useLocation} from "react-router";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

const useStyles = makeStyles((theme) => ({
    active : {
        color : "#FFFFFF !important",
        backgroundColor : `${theme.palette.primary.main} !important`
    },
    activeParent : {
        color : `${theme.palette.primary.main} !important`
    }
}));
export const MainListItems = () => {
    const classes = useStyles;
    const location = useLocation();

    console.log(location);
    console.log(routesName.dashboard.index);
    return(
        <div>

            {/*Dashboard*/}
            <ListItem button selected={location.pathname === routesName.dashboard.index}  component={NavLink} to={routesName.dashboard.index} exact activeClassName={classes.active} >
                <ListItemIcon style={{color : "inherit"}} >
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 3 }} selected={location.pathname === routesName.dashboard.remise.path}  component={NavLink} to={routesName.dashboard.remise.path} exact activeClassName={classes.activeParent}>
                        <ListItemIcon style={{color : "inherit"}}>
                            <AssignmentReturnedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Remise" />
                    </ListItemButton>


                </List>
            </Collapse>


            {/*Products*/}
            <ListItemButton style={{fontSize : 50}} selected={location.pathname === routesName.products.index || location.pathname === routesName.products.create || location.pathname === routesName.products.packs.path } component={NavLink} to={routesName.products.index} activeClassName={classes.active}>
                <ListItemIcon style={{color : "inherit"}}>
                    <LocalMallIcon/>
                </ListItemIcon>
                <ListItemText primary="Produits"  />
            </ListItemButton>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 3 }} selected={location.pathname === routesName.products.index}  component={NavLink} to={routesName.products.index} exact activeClassName={classes.activeParent}>
                        <ListItemIcon style={{color : "inherit"}}>
                            <FormatListBulletedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Liste des produits" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 3 }} selected={location.pathname === routesName.products.create} component={NavLink} to={routesName.products.create} exact activeClassName={classes.activeParent}>
                        <ListItemIcon style={{color : "inherit"}}>
                            <AddCircleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Nouveau produit"  />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 3 }}   component={NavLink} selected={location.pathname === routesName.products.packs.path} to={routesName.products.packs.path} exact activeClassName={classes.activeParent}>
                        <ListItemIcon style={{color : "inherit"}}>
                            <BackpackIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Packs"  />
                    </ListItemButton>

                </List>
            </Collapse>

            {/*Orders*/}
            <ListItemButton style={{fontSize : 50}} component={NavLink} selected={[routesName.orders.index.path,routesName.orders.create].indexOf(location.pathname) >= 0} to={routesName.orders.index.path} activeClassName={classes.active}>
                <ListItemIcon style={{color : "inherit"}}>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Commandes"  />
            </ListItemButton>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 3 }}  component={NavLink} selected={location.pathname === routesName.orders.index.path} to={routesName.orders.index.path} exact activeClassName={classes.activeParent}>
                        <ListItemIcon style={{color : "inherit"}}>
                            <FormatListBulletedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Liste des commandes" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 3 }} component={NavLink} to={routesName.orders.create} selected={location.pathname === routesName.orders.create} exact activeClassName={classes.activeParent}>
                        <ListItemIcon style={{color : "inherit"}}>
                            <AddCircleIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Nouvelle commande"  />
                    </ListItemButton>
                </List>
            </Collapse>

            {/*<ListItem button onClick={() => handleOpenMenu('orders')}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <ShoppingCartIcon />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Orders" />*/}

            {/*</ListItem>*/}
            {/*<Collapse in timeout="auto" unmountOnExit>*/}
            {/*    <List component="div" disablePadding>*/}
            {/*        <ListItemButton sx={{ pl: 3 }} component={NavLink} to="/orders/list" exact activeClassName={classes.active}>*/}
            {/*            <ListItemIcon style={{color:"inherit"}}>*/}
            {/*                <FormatListBulletedIcon/>*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText primary="Liste des commandes" />*/}
            {/*        </ListItemButton>*/}
            {/*        <ListItemButton sx={{ pl: 3 }} component={NavLink} to="/orders/nouveau" exact activeClassName={classes.active}>*/}
            {/*            <ListItemIcon style={{color:"inherit"}}>*/}
            {/*                <AddCircleIcon/>*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText primary="Nouveau commande" />*/}
            {/*        </ListItemButton>*/}
            {/*    </List>*/}
            {/*</Collapse>*/}

            {/*Stores*/}
            <ListItem button component={NavLink} selected={location.pathname === routesName.stores.index.path} to={routesName.stores.index.path} exact activeClassName={classes.active} >
                <ListItemIcon style={{color:"inherit"}}>
                    <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Magasins" />
            </ListItem>
        </div>
    )
};

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Saved reports</ListSubheader>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current month" />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Last quarter" />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Year-end sale" />
        </ListItem>
    </div>
);
