import React, { useContext } from "react";
import OrderStats from "./subPage/orderStats";
import Grid from "@mui/material/Grid";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DeliveredOrderStats from "./component/deliveredOrderStats";
import MoneyStats from "./component/moneyStats";
import ReturnedOrders from "./component/returnedOrders";
import ProductsSales from "./component/productsSales";
import ClientByGov from "./component/ClientByGov";
import { DashboardContext } from "./home";

export default function AdminDashboard() {
    const { orderOftheMonth, isLoading } = useContext(DashboardContext);

    // Calculate product stats from orderOftheMonth
    const calculateProductStats = () => {
        if (!orderOftheMonth || !orderOftheMonth.orders || isLoading) {
            return [];
        }

        const productMap = {};
        orderOftheMonth.orders.forEach((order) => {
            if (order.items && Array.isArray(order.items)) {
                order.items.forEach((item) => {
                    const productId = item.product?.id;
                    if (!productId || !item.quantity || !item.product?.price || !item.product?.cost) return;

                    if (!productMap[productId]) {
                        productMap[productId] = {
                            name: item.product.name || "Unknown",
                            sales: 0,
                            totalAmount: 0,
                            totalCost: 0,
                        };
                    }

                    const qty = Number(item.quantity);
                    const price = Number(item.product.price);
                    const cost = Number(item.product.cost);

                    productMap[productId].sales += qty;
                    productMap[productId].totalAmount += qty * price;
                    productMap[productId].totalCost += qty * cost;
                });
            }
        });

        return Object.entries(productMap).map(([id, data]) => ({
            id,
            ...data,
            utility: data.totalAmount - data.totalCost, // Profit
        }));
    };

    const productStats = calculateProductStats();

    return (
        <Grid container item xs={12} spacing={2}>
            {/* Existing Stats Section */}
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} md={4}>
                    <DeliveredOrderStats isLoading={isLoading} data={orderOftheMonth} />
                </Grid>
                <Grid container item xs={12} md={4} sm={6}>
                    <MoneyStats isLoading={isLoading} data={orderOftheMonth} />
                </Grid>
                <Grid container item xs={12} md={4} sm={6}>
                    <ReturnedOrders isLoading={isLoading} data={orderOftheMonth} />
                </Grid>
            </Grid>

            {/* Charts Section */}
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} md={6}>
                    <Grid container item xs={12}>
                        <Paper
                            elevation={8}
                            sx={{
                                width: "100%",
                                padding: 2,
                                color: "white",
                                backgroundColor: (t) => t.palette.primary.main,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                        >
                            <Typography fontWeight={600}>Stats de commandes | 6 derniers mois</Typography>
                        </Paper>
                    </Grid>
                    <Grid container item xs={12}>
                        <Paper
                            elevation={8}
                            sx={{
                                width: "100%",
                                height: 400,
                                padding: 2,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                        >
                            <OrderStats />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={6}>
                    <Grid container item xs={12}>
                        <Paper
                            elevation={8}
                            sx={{
                                width: "100%",
                                padding: 2,
                                color: "white",
                                backgroundColor: (t) => t.palette.primary.main,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                        >
                            <Typography fontWeight={600}>Top 10 Produit Vendu:</Typography>
                        </Paper>
                    </Grid>
                    <Grid container item xs={12}>
                        <Paper
                            elevation={8}
                            sx={{
                                width: "100%",
                                height: 400,
                                padding: 2,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                        >
                            <ProductsSales />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={12}>
                    <Grid container item xs={12}>
                        <Paper
                            elevation={8}
                            sx={{
                                width: "100%",
                                padding: 2,
                                color: "white",
                                backgroundColor: (t) => t.palette.primary.main,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                        >
                            <Typography fontWeight={600}>Gouvernorat par nombre de commandes:</Typography>
                        </Paper>
                    </Grid>
                    <Grid container item xs={12}>
                        <Paper
                            elevation={8}
                            sx={{
                                width: "100%",
                                height: 400,
                                padding: 2,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                        >
                            <ClientByGov />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            {/* New Product Sales List Section */}
            <Grid container item xs={12} spacing={2} sx={{ mt: 2 }}>
                <Grid container item xs={12}>
                    <Paper
                        elevation={8}
                        sx={{
                            width: "100%",
                            padding: 2,
                            color: "white",
                            backgroundColor: (t) => t.palette.primary.main,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                    >
                        <Typography fontWeight={600}>Liste des produits vendus (Mois en cours)</Typography>
                    </Paper>
                </Grid>
                <Grid container item xs={12}>
                    <Paper
                        elevation={8}
                        sx={{
                            width: "100%",
                            padding: 2,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}
                    >
                        {isLoading ? (
                            <Typography variant="body1" color="text.secondary" align="center">
                                Chargement des données...
                            </Typography>
                        ) : productStats.length === 0 ? (
                            <Typography variant="body1" color="text.secondary" align="center">
                                Aucun produit vendu ce mois-ci
                            </Typography>
                        ) : (
                            <TableContainer sx={{ maxHeight: 400 }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 700 }}>Produit</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 700 }}>Ventes</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 700 }}>Montant Total (DT)</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: 700 }}>Utilité (DT)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productStats.map((product) => (
                                            <TableRow key={product.id}>
                                                <TableCell>{product.name}</TableCell>
                                                <TableCell align="right">{product.sales}</TableCell>
                                                <TableCell align="right">{product.totalAmount.toFixed(2)}</TableCell>
                                                <TableCell align="right" sx={{ color: product.utility >= 0 ? "success.main" : "error.main" }}>
                                                    {product.utility.toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}