

const devMode = false;



export const apiUrl = devMode ? "http://127.0.0.1:8000/" : "https://www.api.sghiri.tn/";
export const headers = (accessToken) => ({
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
    // Add any other headers you need
});
export const managerApiUrl = (url) => apiUrl + "manager" + url;
export const productsImgUrl = (imgName) => apiUrl + "/images/products/" + imgName;
