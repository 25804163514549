import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReportIcon from '@mui/icons-material/Report';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";



export default function ApproveAction({approveAction,title,children}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div onClick={handleClickOpen}>
                {children}
            </div>
            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                </DialogTitle>
                <DialogContent>
                    <Grid container item xs={12} spacing={4}>
                        <Grid container item xs={12} justifyContent={"center"}>
                            <ReportIcon color="primary" sx={{fontSize : 80}}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant={"subtitle1"}>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button variant={"contained"} color={'warning'} onClick={handleClose}>Annuler</Button>
                    <Button variant={"contained"} color={"secondary"} onClick={() => {
                        approveAction();
                        handleClose();
                    }} autoFocus>
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
