import React from "react";
import { TextField, Button, Grid, Box } from "@mui/material"; // @mui/material v5.0.6

const FilterBar = ({ query, onFilter, onReset }) => {
    return (
        <Box sx={{ py: 2 }}>
            <Grid container spacing={2} alignItems="center">
                {/* Search Input */}
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth
                        label="Search Orders"
                        variant="outlined"
                        value={query.value}
                        onChange={(e) => query.setValue(e.target.value)}
                        size="small" // MUI v5 compatible prop
                        InputLabelProps={{
                            shrink: true, // Ensures label doesn't overlap
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "grey.400",
                                },
                                "&:hover fieldset": {
                                    borderColor: "primary.main",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "primary.main",
                                },
                            },
                        }}
                    />
                </Grid>

                {/* Search Button */}
                <Grid item xs={6} sm={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onFilter}
                        fullWidth
                        size="medium" // MUI v5 compatible
                        sx={{
                            height: "40px", // Matches TextField height
                            textTransform: "none", // Prevents uppercase
                            "&:hover": {
                                bgcolor: "primary.dark",
                            },
                        }}
                    >
                        Search
                    </Button>
                </Grid>

                {/* Reset Button */}
                <Grid item xs={6} sm={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onReset}
                        fullWidth
                        size="medium"
                        sx={{
                            height: "40px",
                            textTransform: "none",
                            "&:hover": {
                                borderColor: "primary.dark",
                                color: "primary.dark",
                            },
                        }}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FilterBar;