import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    IconButton,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    CircularProgress,
    Box,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UserContext from "../../../../../context/userContext";
import AlertsContext from "../../../../../context/alertsContext";
import apiCall from "../../../../../api/axios_config";

// Validation Schema
const validationSchema = yup.object({
    name: yup.string().required("Le nom de la promotion est obligatoire"),
    dateRange: yup
        .array()
        .min(2, "Dates de début et de fin obligatoires")
        .required(),
    products: yup
        .array()
        .min(1, "Veuillez sélectionner au moins un produit")
        .required(),
    reduction: yup
        .number()
        .min(0, "La réduction doit être positive")
        .max(100, "La réduction ne peut pas dépasser 100%")
        .required("La réduction est obligatoire"),
});

export default function EditPromoModal({ promo, setPromos, productsList }) {
    const { accessToken } = useContext(UserContext);
    const { setProgressBar, setSnackBar } = useContext(AlertsContext);
    const [open, setOpen] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: promo.promo_name || "",
            dateRange: [new Date(promo.start), new Date(promo.end)],
            reduction: promo.reduction || 0,
            products: promo.products?.map(p => p.id) || [],
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                setProgressBar(true);
                const data = new FormData();
                data.append("id", promo.id);

                // Always send all fields including products
                data.append("promo_name", values.name);
                data.append("start", moment(values.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"));
                data.append("end", moment(values.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"));
                data.append("reduction", values.reduction);
                values.products.forEach(id => data.append("products[]", id));

                const res = await apiCall(accessToken).post("/promos/update", data);

                // Update state with the complete promo including products
                setPromos((prev) =>
                    prev.map((item) =>
                        item.id === promo.id ? res.data.promo : item
                    )
                );

                setSubmitSuccess(true);

                setSnackBar({
                    message: "Promotion mise à jour avec succès",
                    severity: "success",
                    status: true,
                });

                setTimeout(() => {
                    handleClose();
                    setSubmitSuccess(false);
                }, 1500);
            } catch (error) {
                setSnackBar({
                    message: error.response?.data?.message || "Erreur lors de la mise à jour de la promotion",
                    severity: "error",
                    status: true,
                });
            } finally {
                setProgressBar(false);
            }
        },
    });

    useEffect(() => {
        const productIds = promo.products?.map((product) => product.id) || [];
        formik.setValues({
            name: promo.promo_name,
            dateRange: [new Date(promo.start), new Date(promo.end)],
            reduction: promo.reduction,
            products: productIds,
        });
    }, [promo]);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSubmitSuccess(false);
        formik.resetForm();
    };

    const handleProductsChange = (event) => {
        const value = event.target.value;
        formik.setFieldValue("products", value);
    };

    return (
        <div>
            <IconButton color="primary" onClick={handleClickOpen}>
                <Edit />
            </IconButton>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
                <DialogTitle>Modifier la Promotion</DialogTitle>
                <DialogContent>
                    {submitSuccess ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            minHeight={300}
                        >
                            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
                            <Typography variant="h6" mt={2}>
                                Promotion mise à jour avec succès
                            </Typography>
                        </Box>
                    ) : (
                        <Grid container spacing={3} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    name="name"
                                    label="Nom de la Promotion"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateRangePicker
                                    minDate={new Date()}
                                    value={formik.values.dateRange}
                                    onChange={(values) => formik.setFieldValue("dateRange", values)}
                                    format="dd/MM/yyyy"
                                />
                                {formik.touched.dateRange && formik.errors.dateRange && (
                                    <Typography color="error" variant="caption">
                                        {formik.errors.dateRange}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormControl fullWidth>
                                    <InputLabel>Produits</InputLabel>
                                    <Select
                                        multiple
                                        name="products"
                                        value={formik.values.products}
                                        onChange={handleProductsChange}
                                        onBlur={formik.handleBlur}
                                        input={<OutlinedInput label="Produits" />}
                                        renderValue={(selected) =>
                                            selected
                                                .map((id) => productsList.find((item) => item.id === id)?.name)
                                                .filter(Boolean)
                                                .join(", ")
                                        }
                                    >
                                        {productsList?.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                <Checkbox
                                                    checked={formik.values.products.indexOf(item.id) > -1}
                                                />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.products && formik.errors.products && (
                                        <Typography color="error" variant="caption">
                                            {formik.errors.products}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    required
                                    type="number"
                                    name="reduction"
                                    label="Réduction (%)"
                                    value={formik.values.reduction}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.reduction && Boolean(formik.errors.reduction)}
                                    helperText={formik.touched.reduction && formik.errors.reduction}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={handleClose}
                        disabled={formik.isSubmitting}
                    >
                        Annuler
                    </Button>
                    {!submitSuccess && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                            startIcon={
                                formik.isSubmitting ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : null
                            }
                        >
                            {formik.isSubmitting ? "Mise à jour..." : "Mettre à jour"}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}