import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import moment from "moment";
import OrderInfos from "./components/infos";
import DeliveryStatus from "./components/delivery_status";
import OrderActions from "./actions";
import SelectOrder from "./components/selectOrder";
import FilterBar from "./components/FilterBar";
import OrderContext from "../../../../context/OrderContext";

export default function DesktopTable() {
    const { orders, setOrders } = useContext(OrderContext);
    const [filteredOrders, setFilteredOrders] = useState(orders);

    const handleFilter = ({ searchText, deliveryDate }) => {
        const filtered = orders.filter((order) => {
            const commandeNumberStr = order.commandeNumber.toString();
            const clientNameStr = order.client.fullName.toLowerCase();
            const amountStr = order.amount.toString();
            const delegationStr = order.client.delegation.name.toLowerCase();
            const statusStr = order.status.toLowerCase();

            const matchesSearchText =
                commandeNumberStr.includes(searchText.toLowerCase()) ||
                clientNameStr.includes(searchText.toLowerCase()) ||
                amountStr.includes(searchText.toLowerCase()) ||
                delegationStr.includes(searchText.toLowerCase()) ||
                statusStr.includes(searchText.toLowerCase());

            const matchesDeliveryDate = deliveryDate
                ? moment(order.deliveryDate).isSame(moment(deliveryDate), "day")
                : true;

            return matchesSearchText && matchesDeliveryDate;
        });

        setFilteredOrders(filtered);
    };

    useEffect(() => {
        setFilteredOrders(orders);
    }, [orders]);

    return (
        <Grid container item xs={12} rowSpacing={2}>
            {filteredOrders.map((order, index) => (
                <Grid container item xs={12} key={index}>
                    <Paper sx={{ width: "100%"  }}>
                        <Grid container columnSpacing={1} item sx={{  padding: 2 }} xs={12} alignItems="center">
                            <Grid container item justifyContent="center" xs={1}>
                                <SelectOrder order={order} />
                            </Grid>
                            <Divider orientation="vertical" />
                            <Grid container item justifyContent="center" xs={1}>
                                <Stack alignItems="center">
                                    <Typography fontWeight={500} fontSize={13}>{order.commandeNumber}</Typography>
                                    <Typography
                                        fontSize={11}
                                        fontWeight={400}
                                        color="primary"
                                    >
                                        {moment(order.created_at).format("D-MM-Y")}
                                    </Typography>
                                    <Typography
                                        fontSize={11}
                                        fontWeight={400}
                                    >
                                        {moment(order.created_at).format("hh:mm A")}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" />
                            <Grid container item justifyContent="center" xs={2}>
                                <Stack alignItems="center">
                                    <Typography noWrap>{order.client.fullName}</Typography>
                                    <Typography sx={{ fontSize: 13 }} color="primary">
                                        {order.client.phone} {order.client.phoneTwo && "- " + order.client.phoneTwo}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" />
                            <Grid container item justifyContent="center" xs={1}>
                                <Typography>{order.amount} DT</Typography>
                            </Grid>
                            <Divider orientation="vertical" />
                            <Grid container item justifyContent="center" xs={2}>
                                <OrderInfos order={order} />
                            </Grid>
                            <Divider orientation="vertical" />
                            <Grid container item justifyContent="center" xs={2}>
                                <Stack alignItems="center" spacing={1}>
                                    <DeliveryStatus order={order} setOrders={setOrders} />
                                    <Typography noWrap fontWeight="bolder" align="center" variant="body2">
                                        {order.client.delegation.gov.name} - {order.client.delegation.name}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" />
                            <Grid container item justifyContent="center" xs={2.8}>
                                <OrderActions order={order} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
}
