import React, { useContext, useEffect, useState } from "react";
import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";

import moment from "moment";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {calculateDiscount, calculateTotal, sousTotal} from "../../../helpers/functions";
import UserContext from "../../../context/userContext";
import apiCall from "../../../api/axios_config";


// Register fonts
Font.register({ family: "Roboto", src: "/fonts/Roboto/Roboto-Regular.ttf" });
Font.register({ family: "Roboto-Bold", src: "/fonts/Roboto/Roboto-Black.ttf" });
Font.register({ family: "Cairo", src: "/fonts/Cairo-VariableFont_wght.ttf" });

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#ffffff",
        fontFamily: "Roboto-Bold",
        fontWeight: 900,
        padding: 10,
    },
    gridContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    orderContainer: {
        width: "48%", // Two orders per row
        marginBottom: 20,
        border: "1px solid #ccc",
        borderRadius: 10,
        padding: 10,
    },
    clientInfo: {
        marginBottom: 10,
    },
    clientName: {
        fontSize: 12,
        fontFamily: "Cairo",
        fontWeight: "bold",
    },
    clientPhone: {
        fontSize: 10,
        fontFamily: "Cairo",
        color: "#666",
    },
    nid: {
        fontSize: 10,
        fontFamily: "Cairo",
        color: "#666",
        marginTop: 5,
    },
    itemsHeader: {
        flexDirection: "row",
        backgroundColor: "#f0f0f0",
        borderRadius: 5,
        padding: 5,
        fontFamily: "Roboto",
        fontSize: 10,
        marginBottom: 5,
    },
    itemRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 9,
        fontFamily: "Cairo",
        dir : "rtl",
        marginBottom: 3,
    },
});

// Component to render a single order
const Order = ({ order }) => (
    <View style={styles.orderContainer}>
        {/* Client Information */}
        <View style={styles.clientInfo}>
            <Text style={styles.clientName}>{order.client.fullName}</Text>
            <Text style={styles.clientPhone}>Phone: {order.client.phone}</Text>
            {order.client.nid && <Text style={styles.nid}>NID: {order.client.nid}</Text>}
        </View>

        {/* Order Items */}
        <View style={styles.itemsHeader}>
            <Text style={{ flex: "0 0 60%" }}>Product</Text>
            <Text style={{ flex: "0 0 20%", textAlign: "right" }}>Qty</Text>
            <Text style={{ flex: "0 0 20%", textAlign: "right" }}>Price</Text>
        </View>
        {order.items.map((item, idx) => (
            <View key={idx} dir={"rtl"} style={styles.itemRow}>
                <Text   style={{ flex: "0 0 60%" }}>{item.product.name}</Text>
                <Text style={{ flex: "0 0 20%", textAlign: "right" }}>{item.quantity}</Text>
                <Text style={{ flex: "0 0 20%", textAlign: "right" }}>{item.price.toFixed(3)}</Text>
            </View>
        ))}
    </View>
);

// Component to render 4 orders per page
const OrderItems = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const { accessToken } = useContext(UserContext);
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        apiCall(accessToken)
            .get(`/orders/get/${id}`)
            .then((res) => setOrders(res.data.order))
            .catch((e) => {
                if (e.response.status === 404) history.push("/404");
            })
            .finally(() => setLoading(false));
    }, [accessToken, id, history]);

    // Split orders into chunks of 4
    const chunkedOrders = [];
    for (let i = 0; i < orders.length; i += 10) {
        chunkedOrders.push(orders.slice(i, i + 10));
    }

    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <PDFViewer width={"100%"} height={"100%"}>
                <Document>
                    {!loading &&
                        chunkedOrders.map((chunk, pageIndex) => (
                            <Page key={pageIndex} size="A4" style={styles.page}>
                                <View style={styles.gridContainer}>
                                    {chunk.map((order, orderIndex) => (
                                        <Order key={orderIndex} order={order} />
                                    ))}
                                </View>
                            </Page>
                        ))}
                </Document>
            </PDFViewer>
        </div>
    );
};

export default OrderItems;