import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import Image from "mui-image";
import {apiUrl} from "../../../common";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router";
import ApproveAction from "../../../component/common/approveAction";
import UserContext from "../../../context/userContext";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const useStyles = makeStyles((theme) => ({
    span : {
        color : theme.palette.primary.main,
        fontWeight : 900,
    }
}));

export default function ProductCard({item, image, editLink, deleteLink}){
    const classes = useStyles();
    const {accessToken} = useContext(UserContext);
    const history = useHistory();
    function SpanTitle(){
        return(
            <Grid container item xs={12} justifyContent="center">
                <Typography>Voulez vous vraiment supprimer le pack</Typography>
                <Typography color={"primary"}>{item.name}</Typography>
            </Grid>
        )
    }

    return(
        item &&
        <Grid container rowSpacing={2}  item xs={12}>
            <Grid sx={{ height : 250}} container item xs={12}>
                <Image
                    src={`${apiUrl}images/products/${image ? image : "placeholder.jpg" }`}
                    fit="cover"
                    easing="ease"
                    width="100%"
                />
            </Grid>
            <Grid container item xs={12}>
                <Typography variant="body1">{item.name}</Typography>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={6}>
                    <Typography><span className={classes.span}>Nbr produits :</span> {item.products.length}</Typography>
                </Grid>
                <Grid container item xs={6}>
                    <Typography><span className={classes.span}>Prix:</span> {item.price.toFixed(3)}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Accordion sx={{width : "100%"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Description :</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {item.description}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid  container item  xs={12}>
                <Stack spacing={2} direction="row">
                    <Button variant="outlined" color="secondary" startIcon={<EditIcon/>} onClick={() => history.push(editLink)}>Editer</Button>
                    <ApproveAction approveAction={()=>deleteLink(item)} title={<SpanTitle/>}>
                        <Button variant="outlined" startIcon={<DeleteForeverIcon/>}  >Supprimer</Button>
                    </ApproveAction>
                </Stack>
            </Grid>
        </Grid>
    )
}
