import React, {useContext} from "react";
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import {getIn} from "formik";
import apiCall from "../../../../api/axios_config";
import UserContext from "../../../../context/userContext";
import EditClientModal from "../../clients/editClientModal";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";


export default function ClientInformation({formik,setClientList,govs,activeGov,setActiveGov,oldClient,updateOldClient,deleteOldClient,deleteClientInfo = true}){

    const {accessToken} = useContext(UserContext);
    function filterSelectedGov(id) {
        formik.setFieldValue('client.deleg_id', "");
        const filtred = govs.find((gov) => gov.id === id);
        setActiveGov({
            gov: filtred.id,
            delegations: filtred.delegations
        })
    }

    function handlePhoneInput(value, field : "client.phone") {
        value = value.replace(/\s/g, "");
        formik.setFieldValue(field,value);
        if(value.length >= 3 && value.length <=8){
            apiCall(accessToken)
                .get(`/clients/all?phone=${value}&withDeleg=true`)
                .then(res => {
                    setClientList(res.data);
                } ).catch(e => {})
        }else {
            setClientList(null)
        }
    }

    return(
        <Paper sx={{width: "100%", padding: 2}}>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    <Grid container item xs={8}>
                        <Typography variant={"body1"}>
                            Informations client:
                        </Typography>
                    </Grid>
                    {oldClient &&
                        <Grid container item xs={4} justifyContent="flex-end">
                            <Stack direction="row" spacing={2}>
                                {deleteClientInfo  && <Button variant="contained" onClick={deleteOldClient}>Supprimer inforamtions</Button>}
                                <EditClientModal updateOldClient={updateOldClient}  oldClient={oldClient} govs={govs} activeGov={activeGov}/>
                            </Stack>
                        </Grid>
                    }
                </Grid>
                <Grid container item spacing={2} md={12} xs={12}>
                    <Grid container item md={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="client.fullName"
                            label="Nom et prénom"
                            variant="outlined"
                            disabled={Boolean(oldClient)}
                            value={formik.values.client.fullName}
                            onChange={formik.handleChange}
                            error={getIn(formik.touched, "client.fullName") && Boolean(getIn(formik.errors, "client.fullName"))}
                            helperText={getIn(formik.touched, "client.fullName") && getIn(formik.errors, "client.fullName")}
                        />
                    </Grid>
                    <Grid container item md={3} xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="client.phone"
                            label="Télephone"
                            variant="outlined"
                            disabled={Boolean(oldClient)}
                            value={formik.values.client.phone}
                            onChange={(e) => handlePhoneInput(e.target.value, 'client.phone')}
                            error={getIn(formik.touched, "client.phone") && Boolean(getIn(formik.errors, "client.phone"))}
                            helperText={getIn(formik.touched, "client.phone") && getIn(formik.errors, "client.phone")}
                        />
                    </Grid>
                    <Grid container item md={3} xs={12}>
                        <TextField
                            fullWidth
                            required
                            name="client.phoneTwo"
                            id="description"
                            label="Télephone 2"
                            variant="outlined"
                            disabled={Boolean(oldClient)}
                            value={formik.values.client.phoneTwo}
                            onChange={(e) => handlePhoneInput(e.target.value, 'client.phoneTwo')}
                            error={getIn(formik.touched, "client.phoneTwo") && Boolean(getIn(formik.errors, "client.phoneTwo"))}
                            helperText={getIn(formik.touched, "client.phoneTwo") && getIn(formik.errors, "client.phoneTwo")}
                        />
                    </Grid>
                    <Grid container item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel required shrink={true} id="govs">Governerat</InputLabel>
                            <Select
                                required
                                disabled={Boolean(oldClient)}
                                labelId="demo-simple-select-label"
                                value={activeGov.gov}
                                label="Governerat   s"
                                onChange={(event) => filterSelectedGov(event.target.value)}
                            >

                                {govs && govs.map(gov => {
                                    return (
                                        <MenuItem key={`store-${gov.id}`} value={gov.id}>{gov.name}</MenuItem>
                                    )
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel required shrink={true} id="govs">Delegation</InputLabel>
                            <Select
                                value={formik.values.client.deleg_id}
                                name={"client.deleg_id"}
                                label="Delegation"
                                disabled={Boolean(oldClient)}
                                onChange={formik.handleChange}
                            >

                                {activeGov.delegations && activeGov.delegations.map(gov => {
                                    return (
                                        <MenuItem key={`store-${gov.id}`} value={gov.id}>{gov.name}</MenuItem>
                                    )
                                })}

                            </Select>
                            <FormHelperText error={getIn(formik.touched, "client.deleg_id") && Boolean(getIn(formik.errors, "client.deleg_id"))}>{getIn(formik.touched, "client.deleg_id") && getIn(formik.errors, "client.deleg_id")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={6}>
                        <TextField
                            required
                            fullWidth
                            name="client.address"
                            label="Address"
                            variant="outlined"
                            disabled={Boolean(oldClient)}
                            value={formik.values.client.address}
                            onChange={formik.handleChange}
                            error={getIn(formik.touched, "client.address") && Boolean(getIn(formik.errors, "client.address"))}
                            helperText={getIn(formik.touched, "client.address") && getIn(formik.errors, "client.address")}
                        />
                    </Grid>
                    <Grid container item xs={6}>
                        <TextField
                            required
                            fullWidth
                            name="client.mf"
                            label="Matricule Fiscal Client"
                            variant="outlined"
                            disabled={Boolean(oldClient)}
                            value={formik.values.client.mf}
                            onChange={formik.handleChange}
                            error={getIn(formik.touched, "client.mf") && Boolean(getIn(formik.errors, "client.mf"))}
                            helperText={getIn(formik.touched, "client.mf") && getIn(formik.errors, "client.mf")}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>

    )

}
