import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import * as yup from 'yup';
import { useFormik, FieldArray } from "formik";
import { Grid, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import UserContext from "../../../context/userContext";
import DropZonePreview from "../../../component/dropZone";
import { createProducts } from "../../../api/products";
import { routesName } from "../../../router/router";
import AlertsContext from "../../../context/alertsContext";
import axios from 'axios';

export default function CreateProduct({ setSnackbar }) {
    const { accessToken } = useContext(UserContext);
    const { setProgressBar, setSnackBar } = useContext(AlertsContext);
    const navigate = useHistory();

    const [themes, setThemes] = useState([]);
    const [skills, setSkills] = useState([]);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const themeResponse = await axios.get('/api/themes', {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });
                setThemes(themeResponse.data);

                const skillResponse = await axios.get('/api/skills', {
                    headers: { Authorization: `Bearer ${accessToken}` }
                });
                setSkills(skillResponse.data);
            } catch (error) {
                console.error("Failed to load themes and skills:", error);
            }
        };

        fetchData();
    }, [accessToken]);

    const validationSchema = yup.object({
        name: yup.string().required("Le nom de produit est obligatoire"),
        description: yup.string(),
        cost: yup.number().required("Le coût de la produit est obligatoire").positive().integer(),
        price: yup.number().required("Le prix du vente de la produit est obligatoire").positive(),
        themes: yup.array().required("Au moins un thème est obligatoire"),
        skills: yup.array().required("Au moins une compétence est obligatoire"),
        contents: yup.array().of(
            yup.object().shape({
                content: yup.string().required("Contenu est obligatoire"),
                content_ar: yup.string().required("Contenu en arabe est obligatoire"),
                sizes: yup.array().of(
                    yup.object().shape({
                        size: yup.string().required("La taille est obligatoire"),
                        size_ar: yup.string().required("La taille en arabe est obligatoire"),
                    })
                )
            })
        )
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            description: "",
            cost: "",
            price: "",
            themes: [],
            skills: [],
            contents: [{ content: "", content_ar: "", sizes: [{ size: "", size_ar: "" }] }]
        },
        onSubmit: async (values) => {
            setProgressBar(true);
            const formData = new FormData();

            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('cost', values.cost);
            formData.append('price', values.price);
            files.forEach((file) => {
                formData.append('files[]', file);
            });
            formData.append('themes', JSON.stringify(values.themes));
            formData.append('skills', JSON.stringify(values.skills));
            formData.append('contents', JSON.stringify(values.contents));

            try {
                const response = await createProducts(accessToken, formData);
                setSnackBar({ status: true, message: response.data.message, severity: "success" });
                navigate.push(routesName.products.index);
            } catch (error) {
                setSnackBar({ status: true, message: "Une erreur est survenue", severity: "error" });
            } finally {
                setProgressBar(false);
            }
        }
    });

    return (
        <Grid container item rowSpacing={2} columnSpacing={2} xs={12}>
            <Grid container item xs={12}>
                <Typography variant={"h6"}>
                    Nouveau Produit :
                </Typography>
            </Grid>
            <Grid container item md={6} xs={12}>
                <TextField
                    fullWidth
                    id="name"
                    label="Nom de la produit"
                    type="text"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </Grid>
            <Grid container item md={6} xs={12}>
                <TextField
                    fullWidth
                    id="description"
                    label="Description du produit"
                    type="text"
                    variant="outlined"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                />
            </Grid>
            <Grid container item md={3} xs={12}>
                <TextField
                    fullWidth
                    id="cost"
                    label="Coût de la produit"
                    type="number"
                    variant="outlined"
                    value={formik.values.cost}
                    onChange={formik.handleChange}
                    error={formik.touched.cost && Boolean(formik.errors.cost)}
                    helperText={formik.touched.cost && formik.errors.cost}
                />
            </Grid>
            <Grid container item md={3} xs={12}>
                <TextField
                    fullWidth
                    id="price"
                    label="Prix de vente"
                    type="number"
                    variant="outlined"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                />
            </Grid>

            {/* Themes Selector */}
            <Grid container item md={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Thèmes</InputLabel>
                    <Select
                        multiple
                        value={formik.values.themes}
                        onChange={e => formik.setFieldValue('themes', e.target.value)}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {themes.map((theme) => (
                            <MenuItem key={theme.id} value={theme.id}>
                                {theme.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {/* Skills Selector */}
            <Grid container item md={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Compétences</InputLabel>
                    <Select
                        multiple
                        value={formik.values.skills}
                        onChange={e => formik.setFieldValue('skills', e.target.value)}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {skills.map((skill) => (
                            <MenuItem key={skill.id} value={skill.id}>
                                {skill.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {/* Contents Fields */}
            <FieldArray name="contents">
                {({ push, remove }) => (
                    <>
                        {formik.values.contents.map((content, index) => (
                            <Grid container item xs={12} spacing={2} key={index}>
                                <Grid item md={5} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Contenu"
                                        value={content.content}
                                        onChange={e => formik.setFieldValue(`contents.${index}.content`, e.target.value)}
                                        error={formik.touched.contents?.[index] && Boolean(formik.errors.contents?.[index]?.content)}
                                        helperText={formik.touched.contents?.[index] && formik.errors.contents?.[index]?.content}
                                    />
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Contenu en Arabe"
                                        value={content.content_ar}
                                        onChange={e => formik.setFieldValue(`contents.${index}.content_ar`, e.target.value)}
                                        error={formik.touched.contents?.[index] && Boolean(formik.errors.contents?.[index]?.content_ar)}
                                        helperText={formik.touched.contents?.[index] && formik.errors.contents?.[index]?.content_ar}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Button onClick={() => remove(index)} variant="outlined" color="error">Supprimer Contenu</Button>
                                </Grid>

                                {/* Sizes FieldArray */}
                                <FieldArray name={`contents.${index}.sizes`}>
                                    {({ push: pushSize, remove: removeSize }) => (
                                        <>
                                            {content.sizes.map((size, sizeIndex) => (
                                                <Grid container item xs={12} spacing={2} key={sizeIndex}>
                                                    <Grid item md={5} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Taille"
                                                            value={size.size}
                                                            onChange={e => formik.setFieldValue(`contents.${index}.sizes.${sizeIndex}.size`, e.target.value)}
                                                            error={formik.touched.contents?.[index]?.sizes?.[sizeIndex] && Boolean(formik.errors.contents?.[index]?.sizes?.[sizeIndex]?.size)}
                                                            helperText={formik.touched.contents?.[index]?.sizes?.[sizeIndex] && formik.errors.contents?.[index]?.sizes?.[sizeIndex]?.size}
                                                        />
                                                    </Grid>
                                                    <Grid item md={5} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Taille en Arabe"
                                                            value={size.size_ar}
                                                            onChange={e => formik.setFieldValue(`contents.${index}.sizes.${sizeIndex}.size_ar`, e.target.value)}
                                                            error={formik.touched.contents?.[index]?.sizes?.[sizeIndex] && Boolean(formik.errors.contents?.[index]?.sizes?.[sizeIndex]?.size_ar)}
                                                            helperText={formik.touched.contents?.[index]?.sizes?.[sizeIndex] && formik.errors.contents?.[index]?.sizes?.[sizeIndex]?.size_ar}
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Button onClick={() => removeSize(sizeIndex)} variant="outlined" color="error">Supprimer Taille</Button>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12}>
                                                <Button onClick={() => pushSize({ size: "", size_ar: "" })} variant="outlined">Ajouter Taille</Button>
                                            </Grid>
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Button onClick={() => push({ content: "", content_ar: "", sizes: [{ size: "", size_ar: "" }] })} variant="outlined">Ajouter Contenu</Button>
                        </Grid>
                    </>
                )}
            </FieldArray>

            {/* File Upload */}
            <Grid container item xs={12}>
                <DropZonePreview setFiles={setFiles} />
            </Grid>

            {/* Submit Button */}
            <Grid container item xs={12}>
                <Button onClick={formik.handleSubmit} variant="contained" color="primary">
                    Créer Produit
                </Button>
            </Grid>
        </Grid>
    );
}
