import React, { useContext, useEffect, useState, useCallback } from "react";
import { Grid, Typography, Box } from "@mui/material"; // v5.0.6
import { useLocation, useHistory } from "react-router-dom"; // v5.3.4
import apiCall from "../../../api/axios_config"; // axios v0.24.0
import UserContext from "../../../context/userContext";
import SideBarContext from "../../../context/sideBarContext";
import AlertsContext from "../../../context/alertsContext";
import OrderContext from "../../../context/OrderContext";
import SelectedOrderContext from "../../../context/selectedOrderContext";
import DesktopTable from "./order_table/desktopTable";
import TabletTable from "./order_table/tabletTable";
import MobileTable from "./order_table/mobileTable";
import PaginationComponent from "../../../component/pagination";
import SkeletonTable from "./skeletonTable";
import TableActions from "./order_table/components/table_actions";
import FilterBar from "./order_table/components/FilterBar";

// Utility function to get page from URL
const getPageFromUrl = (search) => {
    const params = new URLSearchParams(search);
    return parseInt(params.get("page")) || 1;
};

const OrderList = () => {
    // State declarations
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState({
        currentPage: getPageFromUrl(window.location.search),
        perPage: 50,
        totalPage: 1,
    });

    // Hooks using package.json versions
    const { accessToken } = useContext(UserContext);
    const { setSidebarOpen } = useContext(SideBarContext);
    const { setProgressBar } = useContext(AlertsContext);
    const location = useLocation(); // react-router-dom v5.3.4
    const history = useHistory(); // react-router-dom v5.3.4

    // Close sidebar on mount
    useEffect(() => {
        setSidebarOpen(false);
        return () => setSidebarOpen(true);
    }, [setSidebarOpen]);

    // Fetch orders with memoization
    const fetchOrders = useCallback(
        async (overrideParams = {}) => {
            setIsLoading(true);
            setProgressBar(true);

            try {
                const response = await apiCall(accessToken).get("/orders/all", {
                    params: {
                        filter: searchText.trim(),
                        per_page: pagination.perPage,
                        page: pagination.currentPage,
                        withClient: true,
                        withItems: true,
                        ...overrideParams,
                    },
                });

                const fetchedOrders = response.data.orders?.data || [];
                setOrders(fetchedOrders);
                setFilteredOrders(fetchedOrders);
                setPagination((prev) => ({
                    ...prev,
                    currentPage: response.data.orders?.current_page || 1,
                    perPage: response.data.orders?.per_page || prev.perPage,
                    totalPage: response.data.orders?.last_page || 1,
                }));
            } catch (error) {
                setFilteredOrders([]);
            } finally {
                setIsLoading(false);
                setProgressBar(false);
            }
        },
        [accessToken, pagination.currentPage, pagination.perPage, searchText, setProgressBar]
    );

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    // Event handlers
    const handleSearch = useCallback(() => {
        setPagination((prev) => ({ ...prev, currentPage: 1 }));
        fetchOrders();
    }, [fetchOrders]);

    const handleResetSearch = useCallback(() => {
        setSearchText("");
        setPagination((prev) => ({ ...prev, currentPage: 1 }));
        fetchOrders({ filter: "" });
    }, [fetchOrders]);

    const handlePageChange = useCallback(
        (page) => {
            setPagination((prev) => ({ ...prev, currentPage: page }));
            const newSearchParams = new URLSearchParams(location.search);
            newSearchParams.set("page", page);
            history.push({
                pathname: location.pathname,
                search: newSearchParams.toString(),
            });
        },
        [location.pathname, history]
    );

    const handlePerPageChange = useCallback(
        (perPage) => {
            setPagination((prev) => ({ ...prev, perPage, currentPage: 1 }));
            handlePageChange(1);
        },
        [handlePageChange]
    );

    const handleSelectedOrders = useCallback((order) => {
        setSelectedOrder((prev) => {
            const exists = prev.some((item) => item.id === order.id);
            return exists
                ? prev.filter((item) => item.id !== order.id)
                : [...prev, { id: order.id, nid: order.nid, items: order.items , amount : order.amount , client : order.client }];
        });
    }, []);

    // Render table with basic responsive handling
    const renderTable = () => {
        if (isLoading) return <SkeletonTable />;

        if (filteredOrders.length === 0) {
            return (
                <Box sx={{ my: 4, textAlign: "center" }}>
                    <Typography variant="h6" color="textSecondary">
                        No orders found. Please try adjusting your filters.
                    </Typography>
                </Box>
            );
        }

        return (
            <Grid item xs={12}>
                {/* Basic responsive handling using sx prop from MUI v5 */}
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <MobileTable orders={filteredOrders} />
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block', lg: 'none' } }}>
                    <TabletTable orders={filteredOrders} />
                </Box>
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <DesktopTable orders={filteredOrders} />
                </Box>
            </Grid>
        );
    };

    return (
        <OrderContext.Provider value={{ orders, setOrders }}>
            <SelectedOrderContext.Provider
                value={{ selectedOrder, handleSelectedOrders, setSelectedOrder }}
            >
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12}>
                            <TableActions
                                pagination={pagination}
                                setPerPage={handlePerPageChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FilterBar
                                query={{ value: searchText, setValue: setSearchText }}
                                onFilter={handleSearch}
                                onReset={handleResetSearch}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <PaginationComponent
                                    pagination={pagination}
                                    handleCurrentPage={handlePageChange}
                                />
                            </Box>
                        </Grid>

                        {renderTable()}

                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <PaginationComponent
                                    pagination={pagination}
                                    handleCurrentPage={handlePageChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </SelectedOrderContext.Provider>
        </OrderContext.Provider>
    );
};

export default OrderList;