import React from "react";
import {Paper} from "@mui/material";
import Grid from "@mui/material/Grid";



export default function CustomPaper({children,css,GridCss,...rest}){
    return(
            <Paper {...rest} sx={{width : "100%",padding :2,...css}}>
                <Grid container item xs={12} spacing={2} sx={{...GridCss}} alignItems="center" alignContent="center">
                    {children}
                </Grid>
            </Paper>
    )
}
