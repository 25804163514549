import React, { useContext, useState } from "react";
import { Stack, IconButton, Tooltip } from "@mui/material"; // @mui/material v5.0.6
import EditIcon from "@mui/icons-material/Edit";
import DownloadingIcon from "@mui/icons-material/Downloading";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteIcon from "@mui/icons-material/Delete";
import ApproveAction from "../../../../component/common/approveAction";
import apiCall from "../../../../api/axios_config";
import UserContext from "../../../../context/userContext";
import OrdersContext from "../../../../context/OrderContext"; // Note: Your import says OrdersContext, not OrderContext
import AlertsContext from "../../../../context/alertsContext";
import EditOrderModal from "../edit";

export default function OrderActions({ order }) {
    const { accessToken } = useContext(UserContext);
    const { orders, setOrders } = useContext(OrdersContext);
    const { setSnackBar } = useContext(AlertsContext);
    const [openModal, setOpenModal] = useState(false);

    // Function to handle delete
    const deleteOrder = (orderId) => {
        apiCall(accessToken)
            .post("/orders/delete/" + orderId)
            .then((res) => {
                const newList = orders.filter((o) => o.id !== orderId);
                setOrders(newList);
                setSnackBar({
                    status: true,
                    message: res.data.message,
                    severity: "success",
                });
            })
            .catch((error) =>
                setSnackBar({
                    status: true,
                    message: error.data?.message || "Error deleting order",
                    severity: "error",
                })
            );
    };

    // Modal handlers
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                sx={{
                    p: 0.5,
                    bgcolor: "grey.100",
                    borderRadius: 1,
                    "& .MuiIconButton-root": {
                        width: 32,
                        height: 32,
                        transition: "all 0.2s ease-in-out",
                    },
                }}
            >
                {/* Edit Button */}
                <Tooltip title="Éditer commande">
                    <IconButton
                        disabled={order.nid !== null}
                        onClick={handleOpenModal}
                        sx={{
                            bgcolor: "success.main",
                            color: "white",
                            "&:hover": { bgcolor: "success.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                    >
                        <EditIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                </Tooltip>

                {/* Download Order PDF */}
                <Tooltip title="Télécharger bon de commande">
                    <IconButton
                        onClick={() => window.open(`/orders/pdf/${order.id}`, "_blank")}
                        sx={{
                            bgcolor: "success.main",
                            color: "white",
                            "&:hover": { bgcolor: "success.dark" },
                        }}
                    >
                        <DownloadingIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                </Tooltip>

                {/* Download Bordereau PDF */}
                <Tooltip title="Télécharger bordereau">
                    <IconButton
                        disabled={!order.nid}
                        onClick={() =>
                            window.open(
                                `https://partner-app.intigo.tn/api/v1/externalapi/bordereau/${order.nid}`,
                                "_blank"
                            )
                        }
                        sx={{
                            bgcolor: "success.main",
                            color: "white",
                            "&:hover": { bgcolor: "success.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                    >
                        <PictureAsPdfIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                </Tooltip>

                {/* Delete Button with Approval */}
                <ApproveAction
                    approveAction={() => deleteOrder(order.id)}
                    title="Voulez-vous vraiment supprimer cette commande ?"
                >
                    <Tooltip title="Supprimer">
                        <IconButton
                            disabled={order.nid}
                            sx={{
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": { bgcolor: "error.dark" },
                                "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                            }}
                        >
                            <DeleteIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                    </Tooltip>
                </ApproveAction>
            </Stack>

            {/* Edit Modal */}
            <EditOrderModal open={openModal} onClose={handleCloseModal} id={order.id} />
        </>
    );
}