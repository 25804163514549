import React, { useContext, useEffect, useState, useCallback } from "react";
import {
    Grid,
    TextField,
    Button,
    Typography,
    Paper,
    Chip,
    Stack,
    IconButton,
    Alert,
    Skeleton,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { Pause, PlayArrow, Block, Edit } from "@mui/icons-material";
import UserContext from "../../context/userContext";
import AlertsContext from "../../context/alertsContext";
import apiCall, { noAuthApiCall } from "../../api/axios_config";
import { getAllProduct } from "../../api/products";
import EditPromoModal from "./components/promos/modals/edit-promo-modal";
import { styled } from "@mui/material/styles";

// Styled Components
const CustomPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.background.paper,
}));

const PromoHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
}));

const PromoCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

// Validation Schema
const validationSchema = yup.object({
    name: yup.string().required("Le nom de la promo est obligatoire"),
    dateRange: yup
        .array()
        .min(2, "Dates de début et de fin obligatoires")
        .required(),
    products: yup
        .array()
        .min(1, "Veuillez sélectionner au moins un produit")
        .required(),
    reduction: yup
        .number()
        .min(0, "La réduction doit être positive")
        .max(100, "La réduction ne peut pas dépasser 100%")
        .required("La réduction est obligatoire"),
});

// Create Promo Section Component
const CreatePromoSection = ({ setPromos, productsList }) => {
    const { accessToken } = useContext(UserContext);
    const { setProgressBar, setSnackBar } = useContext(AlertsContext);

    const formik = useFormik({
        initialValues: {
            name: "",
            dateRange: [new Date(), new Date()],
            products: [],
            reduction: 20,
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setProgressBar(true);
                const data = new FormData();
                data.append("promo_name", values.name);
                data.append("reduction", values.reduction);
                data.append(
                    "start",
                    moment(values.dateRange[0]).format("YYYY-MM-DD HH:mm:ss")
                );
                data.append(
                    "end",
                    moment(values.dateRange[1]).format("YYYY-MM-DD HH:mm:ss")
                );
                values.products.forEach((productId) => data.append("products[]", productId));

                const res = await apiCall(accessToken).post("/promos/create", data);

                setPromos((prev) => [res.data.promo, ...prev]);
                setSnackBar({
                    message: "Promotion ajoutée avec succès",
                    severity: "success",
                    status: true,
                });
                resetForm();
            } catch (error) {
                setSnackBar({
                    message: "Erreur lors de la création de la promotion",
                    severity: "error",
                    status: true,
                });
            } finally {
                setProgressBar(false);
            }
        },
    });

    const handleProductsChange = (event) => {
        const value = event.target.value;
        formik.setFieldValue("products", value);
    };

    return (
        <CustomPaper elevation={3}>
            <Typography variant="h6" gutterBottom>
                Nouvelle Promotion
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        name="name"
                        label="Nom de la Promotion"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateRangePicker
                        minDate={new Date()}
                        value={formik.values.dateRange}
                        onChange={(values) => formik.setFieldValue("dateRange", values)}
                    />
                    {formik.touched.dateRange && formik.errors.dateRange && (
                        <Typography color="error" variant="caption">
                            {formik.errors.dateRange}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} md={8}>
                    <FormControl fullWidth>
                        <InputLabel>Produits</InputLabel>
                        <Select
                            multiple
                            name="products"
                            value={formik.values.products}
                            onChange={handleProductsChange}
                            onBlur={formik.handleBlur}
                            input={<OutlinedInput label="Produits" />}
                            renderValue={(selected) =>
                                selected
                                    .map((id) => productsList.find((item) => item.id === id)?.name)
                                    .filter(Boolean)
                                    .join(", ")
                            }
                        >
                            {productsList &&
                                productsList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        <Checkbox
                                            checked={formik.values.products.indexOf(item.id) > -1}
                                        />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                        {formik.touched.products && formik.errors.products && (
                            <Typography color="error" variant="caption">
                                {formik.errors.products}
                            </Typography>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        required
                        type="number"
                        name="reduction"
                        label="Réduction (%)"
                        value={formik.values.reduction}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.reduction && Boolean(formik.errors.reduction)}
                        helperText={formik.touched.reduction && formik.errors.reduction}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={formik.handleSubmit}
                        disabled={formik.isSubmitting}
                    >
                        Créer la Promotion
                    </Button>
                </Grid>
            </Grid>
        </CustomPaper>
    );
};

// Promo Status Component
const PromoStatus = ({ promo }) => {
    const today = moment();
    const startDate = moment(promo.start + " 00:00:00");
    const endDate = moment(promo.end + " 23:59:59");

    if (today.isBetween(startDate, endDate)) {
        return <Chip label="En cours" color="success" size="small" />;
    }
    if (today.isAfter(endDate)) {
        return <Chip label="Terminée" color="info" size="small" />;
    }
    return <Chip label="Programmée" color="warning" size="small" />;
};

// Main Component
export default function PromoPage() {
    const [productsList, setProductsList] = useState(null);
    const [promos, setPromos] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { accessToken } = useContext(UserContext);
    const { setProgressBar, setSnackBar } = useContext(AlertsContext);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const [productsRes, promosRes] = await Promise.all([
                getAllProduct(accessToken),
                noAuthApiCall("/promos/all").get(""),
            ]);
            setProductsList(productsRes.data.products);
            setPromos(promosRes.data.promos);
        } catch (err) {
            setError("Erreur lors du chargement des données");
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const togglePromoStatus = async (id, isActive) => {
        try {
            setProgressBar(true);
            const data = new FormData();
            data.append("id", id);
            data.append("active", isActive ? 1 : 0);

            const res = await apiCall(accessToken).post("/promos/update", data);

            setPromos((prev) =>
                prev.map((promo) =>
                    promo.id === id
                        ? { ...promo, active: parseInt(res.data.promo.active) }
                        : promo
                )
            );
            setSnackBar({
                message: "Statut mis à jour avec succès",
                severity: "success",
                status: true,
            });
        } catch (error) {
            setSnackBar({
                message: "Erreur lors de la mise à jour du statut",
                severity: "error",
                status: true,
            });
        } finally {
            setProgressBar(false);
        }
    };

    if (loading) {
        return (
            <CustomPaper>
                <Skeleton variant="rectangular" height={400} />
            </CustomPaper>
        );
    }

    if (error) {
        return (
            <CustomPaper>
                <Alert severity="error">{error}</Alert>
            </CustomPaper>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {productsList && (
                    <CreatePromoSection productsList={productsList} setPromos={setPromos} />
                )}
            </Grid>
            <Grid item xs={12}>
                <CustomPaper>
                    <PromoHeader elevation={0}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}><Typography fontWeight="bold">Nom/Code</Typography></Grid>
                            <Grid item xs={2}><Typography fontWeight="bold">Réduction</Typography></Grid>
                            <Grid item xs={2}><Typography fontWeight="bold">Début</Typography></Grid>
                            <Grid item xs={2}><Typography fontWeight="bold">Fin</Typography></Grid>
                            <Grid item xs={1}><Typography fontWeight="bold">Statut</Typography></Grid>
                            <Grid item xs={2}><Typography fontWeight="bold">Actions</Typography></Grid>
                        </Grid>
                    </PromoHeader>

                    {promos?.map((promo) => (
                        <PromoCard key={promo.id} elevation={2}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                    <Stack>
                                        <Typography>{promo.promo_name}</Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            {promo.promo_code}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2}>
                                    <Chip
                                        label={`- ${promo.reduction}%`}
                                        color="success"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>{moment(promo.start).format("DD/MM/YYYY")}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>{moment(promo.end).format("DD/MM/YYYY")}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <PromoStatus promo={promo} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack direction="row" spacing={1}>
                                        <IconButton
                                            disabled={
                                                moment().isAfter(promo.end + " 23:59:59") ||
                                                moment().isBefore(promo.start + " 00:00:00")
                                            }
                                            onClick={() => togglePromoStatus(promo.id, !promo.active)}
                                        >
                                            {moment().isAfter(promo.end + " 23:59:59") ||
                                            moment().isBefore(promo.start + " 00:00:00") ? (
                                                <Block fontSize="small" />
                                            ) : promo.active ? (
                                                <Pause fontSize="small" />
                                            ) : (
                                                <PlayArrow fontSize="small" />
                                            )}
                                        </IconButton>
                                        <EditPromoModal
                                            promo={promo}
                                            productsList={productsList}
                                            setPromos={setPromos}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </PromoCard>
                    ))}
                </CustomPaper>
            </Grid>
        </Grid>
    );
}