import React from "react";
import Grid from "@mui/material/Grid";
import {Paper, Skeleton} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PaidIcon from '@mui/icons-material/Paid';


export default function MoneyStats(props) {

    const {isLoading, data} = props;

    // const [sum,setSum] = useState(0);

    function calculateSum() {
        let sum = 0;
        data.allOrders.forEach((order) => {
            if (order.deliveryStatus === 6) {
                sum += order.amount - order.delivery_price
            }
        })
        return sum;
    }

    return (
        <Grid container item xs={12}>
            <Paper
                elevation={8}
                sx={{
                    width: "100%",
                    padding: 4,
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: (t) => t.palette.primary.main,
                        color: "white"
                    }
                }}
            >
                <Grid container item xs={12} justifyContent="center" spacing={2}>
                    <Grid container item xs={12} justifyContent="center" spacing={2}>
                        <Stack alignItems="center" spacing={2}>
                            <Typography fontWeight="bolder" variant="h6">Argent (sans frais de livraison)</Typography>
                            <PaidIcon
                                sx={{
                                    fontSize: 54
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid container item justifyContent="space-evenly" xs={6} spacing={2}>
                        <Grid container item justifyContent="center" xs={12}>
                            <Typography fontWeight="bolder" variant="h6">Ce Mois</Typography>
                        </Grid>
                        {isLoading ?
                            <Skeleton variant="rectangular" sx={{width: "100%", height: 25, borderRadius: 2}}/>

                            :
                            <Grid container item justifyContent="center" xs={12}>
                                <Typography fontWeight="bolder" variant="h6">{data.totalAmount.toFixed(3)} DT</Typography>
                            </Grid>
                        }
                    </Grid>

                    <Grid container item justifyContent="space-evenly" xs={6} spacing={2}>
                        <Grid container item justifyContent="center" xs={12}>
                            <Typography fontWeight="bolder" variant="h6">Total</Typography>
                        </Grid>
                        {isLoading ?
                            <Skeleton variant="rectangular" sx={{width: "100%", height: 25, borderRadius: 2}}/>

                            :
                            <Grid container item justifyContent="center" xs={12}>
                                <Typography fontWeight="bolder" variant="h6">
                                    {new Intl.NumberFormat('fr-TN', { style: 'currency', currency: 'TND' }).format(calculateSum())}
                                </Typography>
                            </Grid>
                        }
                    </Grid>


                </Grid>
            </Paper>
        </Grid>
    )
}