import React from "react";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { calculateTotal, sousTotal } from "../../../../helpers/functions";

export default function Itemslist({ items, setItems, discount, deliveryPrice, formik }) {

    // Function to delete an item by product id
    function deleteItem(product_id) {
        const filter = items.filter((i) => i.product.id !== product_id);
        setItems(filter);
    }

    // Function to calculate total price per product
    function calculateProductTP(price, qte) {
        return price * qte;
    }

    // Function to handle quantity change (increase only)
    const handleQuantityChange = (product_id, operation) => {
        const updatedItems = items.map((item) => {
            if (item.product.id === product_id) {
                let newQuantity = item.quantity + operation;
                if (newQuantity < 1) newQuantity = 1; // Ensure quantity doesn't go below 1
                return { ...item, quantity: newQuantity };
            }
            return item;
        });
        setItems(updatedItems);
    };

    return (
        items &&
        <Paper sx={{ width: "100%", padding: 2 }}>
            <Grid xs={12} container item spacing={2}>
                <Grid container item xs={12}>
                    <Typography fontWeight="bold">Liste de produits :</Typography>
                </Grid>
                <Grid container sx={{ color: (t) => (t.palette.primary.main), fontWeight: "bold" }} item xs={12}>
                    <Grid xs={4} container item>Produit</Grid>
                    <Grid xs={2} container item>Quantité</Grid>
                    <Grid xs={2} container item>Prix unitaire</Grid>
                    <Grid xs={2} container item>Prix Total</Grid>
                    <Grid xs={2} container item>Actions</Grid>
                </Grid>
                {items.map((item) => {
                    return (
                        <Grid container sx={{ height: 70 }} item key={`item-${item.product.id}`} xs={12}>
                            <Grid container item xs={12}>
                                <Divider sx={{ backgroundColor: "red", width: "100%", height: 3 }} />
                            </Grid>
                            <Grid container item xs={12}>
                                {/* Product Name */}
                                <Grid xs={4} container item>
                                    <Typography>{item.product.name}</Typography>
                                </Grid>

                                {/* Quantity */}
                                <Grid xs={2} container item>
                                    <Grid container item justifyContent="center" alignItems="center">
                                        {/* If quantity is 1, show delete icon instead of minus */}
                                        {item.quantity === 1 ? (
                                            <IconButton
                                                onClick={() => deleteItem(item.product.id)}
                                                sx={{
                                                    "&:hover": {
                                                        color: (t) => t.palette.primary.main
                                                    }
                                                }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() => handleQuantityChange(item.product.id, -1)}
                                                sx={{
                                                    "&:hover": {
                                                        color: (t) => t.palette.primary.main
                                                    }
                                                }}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        )}
                                        {/* Always allow incrementing quantity */}
                                        <Typography>{item.quantity}</Typography>
                                        <IconButton
                                            onClick={() => handleQuantityChange(item.product.id, 1)}
                                            sx={{
                                                "&:hover": {
                                                    color: (t) => t.palette.primary.main
                                                }
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                {/* Price per unit */}
                                <Grid xs={2} container item>
                                    <Typography>{parseInt(item.product.price).toFixed(3)}</Typography>
                                </Grid>

                                {/* Total price */}
                                <Grid xs={2} container item>
                                    <Typography>{calculateProductTP(item.product.price, item.quantity).toFixed(3)}</Typography>
                                </Grid>

                                {/* Placeholder for the delete button (if quantity is > 1) */}
                                <Grid xs={2} container item />
                            </Grid>
                        </Grid>
                    )
                })}

                {/* Subtotal */}
                <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>Sous-total :</Typography>
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>{sousTotal(items).toFixed(3)}</Typography>
                    </Grid>
                </Grid>

                {/* Discount */}
                <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>Remise :</Typography>
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>{discount ? ((sousTotal(items) / 100) * discount).toFixed(3) : '0.000'}</Typography>
                    </Grid>
                </Grid>

                {/* Delivery Price */}
                <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>Livraison :</Typography>
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>{formik.values.order.freeShipping ? "0.000" : deliveryPrice.toFixed(3)}</Typography>
                    </Grid>
                </Grid>

                {/* Total */}
                <Grid container item xs={12} sx={{ color: (t) => t.palette.primary.main, fontWeight: "bold" }} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>Total :</Typography>
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <Typography>
                            {formik.values.order.paymentStatus === 2
                                ? calculateTotal(items, discount, formik.values.order.freeShipping === 1 ? 0 : deliveryPrice).toFixed(3)
                                : formik.values.order.freeShipping === 1 ? "0.000" : deliveryPrice.toFixed(3)}
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    )
}
