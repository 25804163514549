import React, { useContext, useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Chip,
    Tooltip,
    Modal,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    IconButton,
    Stack,
} from "@mui/material";
import SelectedOrderContext from "../../../../../context/selectedOrderContext";
import UserContext from "../../../../../context/userContext";
import axios from "axios";
import GetAppIcon from "@mui/icons-material/GetApp";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CalculateIcon from "@mui/icons-material/Calculate";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Document, Page, Text, View, StyleSheet, Font, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import apiCall from "../../../../../api/axios_config";

// Register Cairo Font from public folder
Font.register({
    family: "Cairo",
    src: "/fonts/Cairo-VariableFont_wght.ttf",
});

// PDF Styles
const styles = StyleSheet.create({
    page: { padding: 30, fontSize: 12, fontFamily: "Cairo" },
    title: { fontSize: 16, marginBottom: 10, textAlign: "center" },
    subtitle: { fontSize: 12, marginBottom: 20, textAlign: "center" }, // Added for pickup date
    table: { display: "table", width: "auto", marginBottom: 10 },
    tableRow: { flexDirection: "row" },
    tableHeader: { backgroundColor: "#f0f0f0" },
    tableCell: { padding: 5, borderWidth: 1, borderColor: "#bfbfbf", textAlign: "left" },
    tableCellHeader: { padding: 5, borderWidth: 1, borderColor: "#bfbfbf", fontWeight: "bold", textAlign: "left" },
    clientName: { width: "30%" },
    phone: { width: "20%" },
    amount: { width: "20%" },
    deliveryStatus: { width: "30%" },
});

export default function TableActions({ pagination, setPerPage }) {
    const { selectedOrder, setSelectedOrder } = useContext(SelectedOrderContext);
    const { userRole, accessToken } = useContext(UserContext);
    const [showShippingQuantities, setShowShippingQuantities] = useState(false);
    const [showTotalAmount, setShowTotalAmount] = useState(false);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if ((showTotalAmount || showShippingQuantities) && selectedOrder?.length > 0) {
            const fetchOrders = async () => {
                setLoading(true);
                setError(null);
                setOrders([]);
                try {
                    const ids = selectedOrder.map((o) => o.id).join(",");
                    const response = await apiCall(accessToken).get(`/orders/get/${ids}`);
                    setOrders(response.data.order); // Assuming response.data.order is an array
                } catch (e) {
                    console.error("Error fetching orders:", e);
                    setError(
                        e.response?.status === 404
                            ? "Une ou plusieurs commandes n'ont pas été trouvées (404)"
                            : "Erreur lors de la récupération des commandes"
                    );
                } finally {
                    setLoading(false);
                }
            };
            fetchOrders();
        }
    }, [showTotalAmount, showShippingQuantities, selectedOrder, accessToken]);

    const calculateShippingQuantities = () => {
        if (!selectedOrder || !Array.isArray(selectedOrder) || selectedOrder.length === 0) {
            return {};
        }
        const itemQuantities = {};
        selectedOrder.forEach((order) => {
            if (order && Array.isArray(order?.items) && order.items.length > 0) {
                order.items.forEach((item) => {
                    if (item && item.product && item.product.name && typeof item.quantity !== "undefined") {
                        const itemId = item.id;
                        if (!itemQuantities[itemId]) {
                            itemQuantities[itemId] = { name: item.product.name, totalNeeded: 0 };
                        }
                        itemQuantities[itemId].totalNeeded += Number(item.quantity) || 0;
                    }
                });
            }
        });
        return itemQuantities;
    };

    const groupQuantities = (quantities) => {
        const grouped = {};
        Object.entries(quantities).forEach(([id, data]) => {
            if (grouped[data.name]) {
                grouped[data.name].totalNeeded += data.totalNeeded;
            } else {
                grouped[data.name] = { ...data };
            }
        });
        return grouped;
    };

    const calculateTotalAmount = () => {
        if (!orders || !Array.isArray(orders) || orders.length === 0) {
            return 0;
        }
        return orders.reduce((sum, order) => sum + (order.amount || 0), 0);
    };

    const generateShippingPDF = async () => {
        const groupedQuantities = groupQuantities(calculateShippingQuantities());
        const data = Object.entries(groupedQuantities).map(([name, data]) => ({
            name: name ? name.replace(/(\d+)/g, "\u200E$1\u200E") : "Unknown Product",
            totalNeeded: data.totalNeeded || 0,
        }));

        const MyDocument = (
            <Document>
                <Page size="A4" style={styles.page}>
                    <Text style={styles.title}>Quantities Needed for Shipping</Text>
                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={[styles.tableCellHeader, styles.clientName]}>Product Name</Text>
                            <Text style={[styles.tableCellHeader, styles.amount]}>Total Quantity Needed</Text>
                        </View>
                        {data.map((row, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={[styles.tableCell, styles.clientName]}>{row.name}</Text>
                            <Text style={[styles.tableCell, styles.amount]}>{row.totalNeeded}</Text>
                </View>
                ))}
            </View>
    </Page>
    </Document>
    );

        const pdfBlob = await pdf(MyDocument).toBlob();
        saveAs(pdfBlob, "shipping_quantities.pdf");
    };

    const generateLocalDeliveryPDF = async () => {
        const data = selectedOrder.map((order) => ({
            clientName: order.client?.fullName || "Unknown",
            phone: order.client?.phone || "N/A",
            amount: order.amount ? `${order.amount} TND` : "0 TND",
            deliveryStatus: "", // Empty for manual entry
            gov: order.client?.delegation?.gov?.name || "N/A",
            delegation: order.client?.delegation?.name || "N/A",
        }));

        // Get today's date formatted as DD-MM-YYYY
        const today = new Date();
        const pickupDate = `${today.getDate().toString().padStart(2, "0")}-${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${today.getFullYear()}`;

        const LocalDeliveryDocument = (
            <Document>
                <Page size="A4" style={styles.page}>
                    <Text style={styles.title}>Local Delivery Orders</Text>
                    <Text style={styles.subtitle}>Pickup Date: {pickupDate}</Text>
                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={[styles.tableCellHeader, styles.clientName]}>Client Name</Text>
                            <Text style={[styles.tableCellHeader, styles.phone]}>Phone</Text>
                            <Text style={[styles.tableCellHeader, styles.clientName]}>Location</Text>
                            <Text style={[styles.tableCellHeader, styles.amount]}>Order Amount</Text>
                            <Text style={[styles.tableCellHeader, styles.deliveryStatus]}>Delivery Status</Text>
                        </View>
                        {data.map((row, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={[styles.tableCell, styles.clientName]}>{row.clientName}</Text>
                                <Text style={[styles.tableCell, styles.phone]}>{row.phone}</Text>
                                <Text style={[styles.tableCell, styles.clientName]}>
                                    {row.gov + "-" + row.delegation}
                                </Text>
                                <Text style={[styles.tableCell, styles.amount]}>{row.amount}</Text>
                                <Text style={[styles.tableCell, styles.deliveryStatus]}>{row.deliveryStatus}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        );

        const pdfBlob = await pdf(LocalDeliveryDocument).toBlob();
        saveAs(pdfBlob, "local_delivery_orders.pdf");
    };

    const ShippingQuantitiesModal = ({ open, onClose, quantities }) => {
        const groupedQuantities = groupQuantities(quantities);

        return (
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "90%", sm: 400 },
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                        maxHeight: "80vh",
                        overflow: "auto",
                        borderRadius: 1,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Quantities Needed for Shipping
                    </Typography>
                    {Object.keys(groupedQuantities).length > 0 ? (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell align="right">Total Quantity Needed</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(groupedQuantities).map(([name, data]) => (
                                        <TableRow key={name}>
                                            <TableCell>{name}</TableCell>
                                            <TableCell align="right">{data.totalNeeded}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PictureAsPdfIcon />}
                                onClick={generateShippingPDF}
                                sx={{ mt: 2, mr: 1 }}
                            >
                                Generate PDF
                            </Button>
                        </>
                    ) : (
                        <Typography>No items to ship</Typography>
                    )}
                    <Button onClick={onClose} sx={{ mt: 2 }} variant="outlined" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
        );
    };

    return (
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton
                        sx={{
                            width: 36,
                            height: 36,
                            color: "white",
                            bgcolor: "success.main",
                            "&:hover": { bgcolor: "success.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                        onClick={() => window.open(`/orders/pdf/${selectedOrder.map((o) => o.id).toString()}`, "_blank")}
                        disabled={!selectedOrder || selectedOrder.length === 0}
                    >
                        <Tooltip title="Télécharger bon de commande">
                            <GetAppIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        sx={{
                            width: 36,
                            height: 36,
                            color: "white",
                            bgcolor: "success.main",
                            "&:hover": { bgcolor: "success.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                        onClick={() => window.open(`/orders/facture/${selectedOrder.map((o) => o.id).toString()}`, "_blank")}
                        disabled={!selectedOrder || selectedOrder.length === 0}
                    >
                        <Tooltip title="Télécharger facture">
                            <ReceiptIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        sx={{
                            width: 36,
                            height: 36,
                            color: "white",
                            bgcolor: "success.main",
                            "&:hover": { bgcolor: "success.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                        disabled={!selectedOrder || selectedOrder.length === 0}
                        onClick={() => {
                            const nids = selectedOrder.filter((o) => o).map((o) => o.nid);
                            axios
                                .post(
                                    "https://partner-app.intigo.tn/api/v1/externalapi/bordereau/",
                                    { nids },
                                    { responseType: "blob" }
                                )
                                .then((response) => {
                                    const fileName = response.headers["content-disposition"].split("filename=")[1];
                                    const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                                    const link = document.createElement("a");
                                    link.href = url;
                                    link.setAttribute("download", fileName);
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                })
                                .catch(() => {});
                        }}
                    >
                        <Tooltip title="Télécharger bordereau de livraison">
                            <PictureAsPdfIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        sx={{
                            width: 36,
                            height: 36,
                            color: "white",
                            bgcolor: "success.main",
                            "&:hover": { bgcolor: "success.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                        onClick={() => window.open(`/orders/orderItems/${selectedOrder.map((o) => o.id).toString()}`, "_blank")}
                        disabled={!selectedOrder || selectedOrder.length === 0}
                    >
                        <Tooltip title="Télécharger liste des articles">
                            <ListAltIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        sx={{
                            width: 36,
                            height: 36,
                            color: "white",
                            bgcolor: "info.main",
                            "&:hover": { bgcolor: "info.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                        onClick={() => selectedOrder?.length > 0 && setShowShippingQuantities(true)}
                        disabled={!selectedOrder || selectedOrder.length === 0}
                    >
                        <Tooltip title="Calculer quantités à expédier">
                            <CalculateIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        sx={{
                            width: 36,
                            height: 36,
                            color: "white",
                            bgcolor: "primary.main",
                            "&:hover": { bgcolor: "primary.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                        onClick={() => setShowTotalAmount(true)}
                        disabled={!selectedOrder || selectedOrder.length === 0}
                    >
                        <Tooltip title="Afficher le montant total">
                            <AttachMoneyIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>

                    {/* New Local Delivery Button */}
                    <IconButton
                        sx={{
                            width: 36,
                            height: 36,
                            color: "white",
                            bgcolor: "secondary.main",
                            "&:hover": { bgcolor: "secondary.dark" },
                            "&.Mui-disabled": { bgcolor: "grey.400", color: "grey.600" },
                        }}
                        onClick={generateLocalDeliveryPDF}
                        disabled={!selectedOrder || selectedOrder.length === 0}
                    >
                        <Tooltip title="Générer PDF livraison locale">
                            <LocalShippingIcon sx={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>
                </Stack>

                <Stack spacing={1} sx={{ mt: 1 }}>
                    <Typography variant="body2">
                        {selectedOrder?.length || 0} Commandes sélectionnées
                    </Typography>
                    {selectedOrder?.length > 0 && (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => setSelectedOrder([])}
                            sx={{ textTransform: "none", p: 0 }}
                        >
                            Désélectionner tout
                        </Button>
                    )}
                </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                >
                    <Typography variant="subtitle2">Par page :</Typography>
                    {[25, 50, 100, 150, 200].map((item) => (
                        <Chip
                            key={item}
                            disabled={pagination?.perPage === item}
                            clickable
                            onClick={() => setPerPage(item)}
                            label={item}
                            variant={pagination?.perPage === item ? "filled" : "outlined"}
                            color="primary"
                            size="small"
                            sx={{ minWidth: 40 }}
                        />
                    ))}
                </Stack>
            </Grid>

            <ShippingQuantitiesModal
                open={showShippingQuantities}
                onClose={() => setShowShippingQuantities(false)}
                quantities={calculateShippingQuantities() || {}}
            />

            <Modal open={showTotalAmount} onClose={() => setShowTotalAmount(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "90%", sm: 400 },
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                        borderRadius: 1,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Montant total des commandes sélectionnées
                    </Typography>
                    {loading ? (
                        <Typography>Récupération des données...</Typography>
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <Typography variant="h4" color="primary">
                            {new Intl.NumberFormat("fr-TN", { style: "currency", currency: "TND" }).format(
                                calculateTotalAmount()
                            )}
                        </Typography>
                    )}
                    <Button
                        onClick={() => setShowTotalAmount(false)}
                        sx={{ mt: 2 }}
                        variant="outlined"
                        color="primary"
                    >
                        Fermer
                    </Button>
                </Box>
            </Modal>
        </Grid>
    );
}