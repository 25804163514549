import React, { useContext, useEffect, useState } from "react";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import { Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import axios from "axios";
import PickUp from "./create_form/store";
import Specification from "./create_form/specification";
import ClientInformation from "./create_form/clientInformation";
import Items from "./create_form/items";
import Itemslist from "./create_form/itemslist";
import DiscountSection from "./create_form/discount";
import OldClient from "./create_form/oldclient";
import { calculateTotal } from "../../../helpers/functions";
import AlertsContext from "../../../context/alertsContext";
import { useHistory } from "react-router-dom";
import Loader from "../../../component/common/loader";
import { useParams } from "react-router";
import moment from "moment";
import * as Yup from "yup"; // Import Yup for validation

export default function EditOrderModal({ id, open, onClose }) {
    const [stores, setStores] = useState({});
    const [activeStores, setActiveStores] = useState({});
    const [govs, setGovs] = useState(null);
    const [products, setProducts] = useState({});
    const [activeGov, setActiveGov] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentQte, setCurrentQte] = useState(1);
    const [currentProduct, setCurrentProduct] = useState("");
    const [deliveryCompanies, setDeliveryCompanies] = useState({});
    const [items, setItems] = useState(null);
    const [itemsError, setItemsError] = useState(false);
    const [order, setOrder] = useState(null);
    const [clientList, setClientList] = useState(null);
    const [oldClient, setOldClient] = useState(null);

    const { accessToken } = useContext(UserContext);
    const { setProgressBar, setSnackBar } = useContext(AlertsContext);
    const history = useHistory();

    // Function to update old client data
    function updateOldClient(data) {
        setOldClient(data);
        formik.setFieldValue("client.deleg_id", data.deleg_id);
        formik.setFieldValue("client.fullName", data.fullName);
        formik.setFieldValue("client.phone", data.phone);
        formik.setFieldValue("client.phoneTwo", data.phoneTwo);
        formik.setFieldValue("client.address", data.address);
        formik.setFieldValue("client.mf", data.mf);
    }

    // Function to delete old client data
    function deleteOldClient() {
        setOldClient(null);
        formik.setFieldValue("client.deleg_id", "");
        formik.setFieldValue("client.fullName", "");
        formik.setFieldValue("client.phone", "");
        formik.setFieldValue("client.phoneTwo", "");
        formik.setFieldValue("client.address", "");
        formik.setFieldValue("client.mf", "");
    }

    // Validation schema using Yup
    const validationSchema = Yup.object({
        client: Yup.object().shape({
            fullName: Yup.string().required("Le nom et prénom du client est obligatoire"),
            phone: Yup.string().required("Télephone obligatoire"),
            // phoneTwo: Yup.string(),
            address: Yup.string().required("Addresse est obligatoire"),
            deleg_id: Yup.number().required("Delegation est obligatoire"),
        }),
        order: Yup.object().shape({
            store_id: Yup.number().required("Le magasin est obligatoire"),
            discount: Yup.number().required("La remise est obligatoire"),
            packageSize: Yup.number().required("La taille du colis est obligatoire"),
            paymentStatus: Yup.number().required("Le statut de paiement est obligatoire"),
            descProduit: Yup.string().required("La description du produit est obligatoire"),
            isExchange: Yup.number().required("L'échange est obligatoire"),
            freeShipping: Yup.number().required("La livraison gratuite est obligatoire"),
            deliveryCompany: Yup.object().required("La société de livraison est obligatoire"),
        }),
    });

    // Formik initialization
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            order: {
                store_id: 1,
                discount: 0,
                packageSize: 1,
                paymentStatus: 2,
                amount: "0",
                descProduit: "Jeux Educatifs",
                isExchange: 1,
                isFragile: 1,
                freeShipping: 0,
                deliveryCompany: deliveryCompanies[0],
            },
            client: {
                clientId: null,
                fullName: "",
                phone: "",
                phoneTwo: "",
                address: "",
                deleg_id: "",
                mf: "",
            },
            items: [],
        },
        onSubmit: (values, { setFieldError }) => {
            setProgressBar(true);
            if (items) {
                const data = {
                    deliveryDate: moment().format("Y-MM-D"),
                    discount: values.order.discount,
                    store_id: values.order.store_id,
                    packageSize: values.order.packageSize,
                    paymentStatus: values.order.paymentStatus,
                    descProduit: values.order.descProduit,
                    amount: calculateTotal(
                        items,
                        values.order.discount,
                        values.order.freeShipping === 1 ? 0 : values.order.deliveryCompany.public_price
                    ),
                    free_delivery: values.order.freeShipping,
                    isExchange: values.order.isExchange,
                    items: items,
                };

                apiCall(accessToken)
                    .post(`/orders/editer/${id}`, data)
                    .then((res) => {
                        setSnackBar({
                            status: true,
                            message: "La Commande a été modifiée avec succès",
                            severity: "success",
                        });
                        onClose(); // Close the modal after success
                    })
                    .catch((error) => {
                        setSnackBar({
                            status: true,
                            message: "Une erreur s'est produite lors de la modification de la commande",
                            severity: "error",
                        });
                    })
                    .finally(() => {
                        setProgressBar(false);
                    });
            } else {
                setItemsError(true);
                setProgressBar(false);
            }
        },
    });

    // Fetch data on component mount
    useEffect(() => {
        if (!open) return; // Prevent fetching when modal is closed

        const fetchData = async () => {
            try {
                const [storesResponse, govsResponse, deliveryCompaniesResponse] = await Promise.all([
                    apiCall(accessToken).get("/stores/all"),
                    apiCall(accessToken).get("/places/govs/all?withDeleg=true"),
                    apiCall(accessToken).get("/delivery_companies/all"),
                ]);

                setStores(storesResponse.data.stores);
                setGovs(govsResponse.data);
                setDeliveryCompanies(deliveryCompaniesResponse.data);
                formik.setFieldValue("order.deliveryCompany", deliveryCompaniesResponse.data[0]);

                const orderResponse = await apiCall(accessToken).get(`/orders/single/${id}`);
                setOrder(orderResponse.data.order);
                setActiveStores(orderResponse.data.order.store_id);

                // Fetch stock for the order's store
                const stockResponse = await apiCall(accessToken).get(
                    `/stores/get_stock/${activeStores}`
                );
                setProducts(stockResponse.data.stock);
            } catch (error) {
                if (error.response?.status === 404) {
                    history.push("/404");
                }
            } finally {
                setLoading(true);
            }
        };

        fetchData();
    }, [open, accessToken, id, activeStores, history]);

    // Update formik values when order is set
    useEffect(() => {
        if (order !== null) {
            formik.setFieldValue("order.store_id", order.store_id);
            formik.setFieldValue("order.discount", order.discount);
            formik.setFieldValue("client.fullName", order.client.fullName);
            formik.setFieldValue("client.phone", order.client.phone);
            formik.setFieldValue("client.phoneTwo", order.client.phoneTwo);
            formik.setFieldValue("client.address", order.client.address);
            formik.setFieldValue("client.mf", order.client.mf);
            formik.setFieldValue("order.freeShipping", order.free_delivery);
            formik.setFieldValue("order.delivery_price", order.delivery_price);
            formik.setFieldValue("order.paymentStatus", order.paymentStatus);
            formik.setFieldValue("order.isExchange", order.isExchange);

            const findGov = govs.find((gov) => gov.id === order.client.delegation.gov_id);
            setActiveGov({
                gov: order.client.delegation.gov_id,
                delegations: findGov.delegations,
            });

            setOldClient(order.client);
            formik.setFieldValue("client.deleg_id", order.client.deleg_id);
            setItems(order.items);
        }
    }, [order, govs]);

    const handleStoreChange = (value) => {
        setLoading(true);
        value = value.target.value;
        setActiveStores(value)
        formik.setFieldValue('order.store_id', value);

        // Example API Call
        apiCall(accessToken)
            .get(`/stores/get_stock/${value}`)
            .then((res) => {
                setProducts(res.data.stock); // Update products
            })
            .catch((err) => {
            }).finally(() => setLoading(false));
    };

    // Update item quantity directly
    const updateItemQuantity = (index, newQuantity) => {
        const updatedItems = [...items];
        updatedItems[index].quantity = newQuantity;
        setItems(updatedItems);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Modifier la Commande</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Grid container item rowSpacing={2} columnSpacing={2} xs={12}>
                        <Grid container item xs={12}>
                            <Typography color={"primary"} variant={"h6"}>
                                Modifier la Commande :
                            </Typography>
                        </Grid>

                        {/* PickUp Section */}
                        <Grid container item xs={12}>
                            <PickUp stores={stores} formik={formik} handleStoreChange={handleStoreChange} deliveryCompanies={deliveryCompanies} />
                        </Grid>

                        {/* Specifications */}
                        <Grid container item xs={12}>
                            <Specification formik={formik} />
                        </Grid>

                        {/* Old Client Section */}
                        <Grid container item xs={12}>
                            {clientList && !oldClient && (
                                <OldClient
                                    govs={govs}
                                    setOldClient={setOldClient}
                                    clientsList={clientList}
                                    setActiveGov={setActiveGov}
                                    formik={formik}
                                />
                            )}
                        </Grid>

                        {/* Client Information */}
                        <Grid container item xs={12}>
                            <ClientInformation
                                deleteClientInfo={false}
                                oldClient={oldClient}
                                setClientList={setClientList}
                                formik={formik}
                                govs={govs}
                                setActiveGov={setActiveGov}
                                activeGov={activeGov}
                                deleteOldClient={deleteOldClient}
                                updateOldClient={updateOldClient}
                            />
                        </Grid>

                        {/* Discount Section */}
                        <Grid container item xs={12}>
                            <DiscountSection formik={formik} />
                        </Grid>

                        {/* Add Items */}
                        <Grid container item xs={12}>
                            <Items
                                formik={formik}
                                itemsError={itemsError}
                                currentQte={currentQte}
                                products={products}
                                setCurrentQte={setCurrentQte}
                                items={items}
                                setItems={setItems}
                                currentProduct={currentProduct}
                                setCurrentProduct={setCurrentProduct}
                                updateItemQuantity={updateItemQuantity} // Pass function to update quantity
                            />
                        </Grid>

                        {/* Items List */}
                        <Grid container item xs={12}>
                            {items && (
                                <Itemslist
                                    formik={formik}
                                    items={items}
                                    setItems={setItems}
                                    discount={formik.values.order.discount}
                                    deliveryPrice={formik.values.order.deliveryCompany.public_price}
                                />
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Loader />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={formik.submitForm} color="primary">Modifier</Button>
            </DialogActions>
        </Dialog>
    );
}
