import React from "react";
import { Grid, Paper, Typography, Stack, Box } from "@mui/material"; // @mui/material v5.0.6
import OrderInfos from "./components/infos";
import DeliveryStatus from "./components/delivery_status";
import SelectOrder from "./components/selectOrder";
import moment from "moment"; // v2.29.3
import OrderActions from "./actions";

export default function MobileTable({ orders }) {
    return (
        <Grid container spacing={2}>
            {orders.map((order) => (
                <Grid item xs={12} key={order.commandeNumber}>
                    <Paper
                        elevation={2}
                        sx={{
                            width: "100%",
                            p: { xs: 1.5, sm: 2 }, // Slightly less padding on mobile
                            borderLeft: (theme) => `4px solid ${theme.palette.primary.main}`,
                            borderRadius: 1,
                            bgcolor: "grey.50",
                            "&:hover": {
                                boxShadow: (theme) => theme.shadows[4],
                            },
                        }}
                    >
                        <Stack spacing={1}>
                            {/* Top Row: Selection and Main Info */}
                            <Stack
                                direction="row"
                                spacing={{ xs: 1, sm: 2 }}
                                alignItems="center"
                                sx={{ width: "100%" }}
                            >
                                <Box sx={{ minWidth: { xs: 50, sm: 70 }, textAlign: "center" }}>
                                    <SelectOrder order={order} />
                                    <Typography
                                        fontWeight={500}
                                        fontSize={12}
                                        color="text.secondary"
                                        sx={{ mt: 0.5 }}
                                    >
                                        {order.commandeNumber}
                                    </Typography>
                                    <Typography
                                        fontSize={11}
                                        fontWeight={400}
                                        color="primary"
                                    >
                                        {moment(order.created_at).format("D-MM-Y")}
                                    </Typography>
                                    <Typography
                                        fontSize={11}
                                        fontWeight={400}
                                        color="primary"
                                    >
                                        {moment(order.created_at).format("HH:mm")}
                                    </Typography>
                                </Box>

                                {/* Name, Phone, Amount Row - Expanded Width */}
                                <Stack
                                    direction="row"
                                    spacing={{ xs: 1, sm: 2 }}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        flexGrow: 1,
                                        bgcolor: "white",
                                        p: 1,
                                        borderRadius: 1,
                                        width: "100%", // Ensure it takes full available width
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontSize: { xs: 14, sm: 16 },
                                            fontWeight: 500,
                                            minWidth: 0, // Allow truncation
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            flex: { xs: 1, sm: 2 }, // Give more space on larger screens
                                        }}
                                    >
                                        {order.client.fullName}
                                    </Typography>
                                    <Typography
                                        component="a"
                                        href={`tel:${order.client.phone}`}
                                        color="primary"
                                        sx={{
                                            fontSize: { xs: 12, sm: 14 },
                                            textDecoration: "none",
                                            "&:hover": { textDecoration: "underline" },
                                            minWidth: 0,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            flex: 1,
                                        }}
                                    >
                                        {order.client.phone}
                                        {order.client.phoneTwo && ` - ${order.client.phoneTwo}`}
                                    </Typography>
                                    <Typography
                                        fontWeight={600}
                                        color="success.main"
                                        sx={{
                                            fontSize: { xs: 14, sm: 16 },
                                            minWidth: "fit-content", // Ensure amount doesn't shrink too much
                                        }}
                                    >
                                        {order.amount} DT
                                    </Typography>
                                </Stack>
                            </Stack>

                            {/* Delivery Status and Location */}
                            <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing={1}
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ width: "100%" }}
                            >
                                <DeliveryStatus order={order} />
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: 12, sm: 13 },
                                        fontWeight: 500,
                                        textAlign: { xs: "center", sm: "right" },
                                        minWidth: 0,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {order.client.delegation.gov.name} - {order.client.delegation.name}
                                </Typography>
                            </Stack>

                            {/* Order Infos and Actions */}
                            <Stack
                                direction="row"
                                spacing={{ xs: 1, sm: 2 }}
                                justifyContent="center"
                                sx={{ width: "100%" }}
                            >
                                <OrderInfos order={order} />
                                <OrderActions order={order} />
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
}