import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Paper} from "@mui/material";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function DropZonePreview(props) {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps,fileRejections} = useDropzone({
        accept: 'image/*',
        maxFiles : props.maxFiles ? props.maxFiles : 100 ,
        multiple : props.maxFiles ? false : true,

        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            props.setFiles(acceptedFiles);
        },
    });
    const fileRejectionItems = fileRejections.map(({ file, errors  }) => {
        return (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}  {e.code}</li>)}
                </ul>

            </li>
        )
    });
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    alt="preview"
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));
    useEffect(() => () => {
        setFiles([])
    }, [props.uploaded]);
    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    return (
        <section className="container" style={{width : "100%"}}>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <Paper elevation={8} sx={{width : "100%" , padding : 4}}>
                    upload Images
                    <aside style={thumbsContainer}>
                        {thumbs}
                    </aside>
                </Paper>

            </div>

        </section>
    );
}

export default DropZonePreview;
