import React, { useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { apiUrl, headers } from "../../../common";
import UserContext from "../../../context/userContext";
import Grid from "@mui/material/Grid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DeleteProduct from "./deleteProduct";
import { routesName } from "../../../router/router";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import debounce from "lodash/debounce"; // For debouncing filter input
import Pagination from "@mui/material/Pagination"; // For pagination controls
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((theme) => ({
    span: {
        color: theme.palette.primary.main,
        fontWeight: 900,
    },
    card: {
        transition: "transform 0.2s",
        "&:hover": {
            transform: "scale(1.02)",
        },
    },
}));

const ProductsList = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState("");
    const [pagination, setPagination] = useState({
        currentPage: 1,
        perPage: 12, // Default items per page
        totalPages: 1,
        totalItems: 0,
    });
    const { accessToken } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();

    const fetchProducts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}manager/products/all`, {
                headers: headers(accessToken),
            });
            const fetchedProducts = response.data.products || [];
            setProducts(fetchedProducts);
            setFilteredProducts(fetchedProducts);
            setPagination((prev) => ({
                ...prev,
                totalItems: fetchedProducts.length,
                totalPages: Math.ceil(fetchedProducts.length / prev.perPage),
            }));
        } catch (err) {
            setError("Erreur lors du chargement des produits");
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    // Debounced filter function with pagination update
    const handleFilterChange = useCallback(
        debounce((value) => {
            const lowerValue = value.toLowerCase();
            const filtered = products.filter(
                (product) =>
                    product.name.toLowerCase().includes(lowerValue) ||
                    String(product.cost).includes(lowerValue) ||
                    String(product.price).includes(lowerValue)
            );
            setFilteredProducts(filtered);
            setPagination((prev) => ({
                ...prev,
                currentPage: 1, // Reset to first page on filter change
                totalItems: filtered.length,
                totalPages: Math.ceil(filtered.length / prev.perPage),
            }));
        }, 300),
        [products]
    );

    const onFilterChange = (e) => {
        const value = e.target.value;
        setFilter(value);
        handleFilterChange(value);
    };

    const handlePageChange = (event, newPage) => {
        setPagination((prev) => ({ ...prev, currentPage: newPage }));
    };

    const handlePerPageChange = (newPerPage) => {
        setPagination((prev) => ({
            ...prev,
            perPage: newPerPage,
            currentPage: 1, // Reset to first page
            totalPages: Math.ceil(prev.totalItems / newPerPage),
        }));
    };

    const updateProductsList = (newList) => {
        setProducts(newList);
        const filtered = newList.filter((product) =>
            product.name.toLowerCase().includes(filter.toLowerCase()) ||
            String(product.cost).includes(filter.toLowerCase()) ||
            String(product.price).includes(filter.toLowerCase())
        );
        setFilteredProducts(filtered);
        setPagination((prev) => ({
            ...prev,
            totalItems: newList.length,
            totalPages: Math.ceil(newList.length / prev.perPage),
        }));
    };

    // Paginate filtered products
    const paginatedProducts = () => {
        const startIndex = (pagination.currentPage - 1) * pagination.perPage;
        const endIndex = startIndex + pagination.perPage;
        return filteredProducts.slice(startIndex, endIndex);
    };

    const ProductSkeleton = () => (
        <Grid container rowSpacing={2} item xs={12} md={6} lg={4}>
            <Grid sx={{ height: 250 }} container item xs={12}>
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </Grid>
            <Grid container item xs={12}>
                <Skeleton width="60%" />
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={6}>
                    <Skeleton width="80%" />
                </Grid>
                <Grid container item xs={6}>
                    <Skeleton width="80%" />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
            <Grid container item xs={12}>
                <Skeleton width="40%" height={40} />
                <Skeleton width="40%" height={40} sx={{ ml: 2 }} />
            </Grid>
        </Grid>
    );

    if (error) {
        return (
            <Grid container item xs={12} justifyContent="center">
                <Typography color="error">{error}</Typography>
                <Button onClick={fetchProducts} variant="contained" sx={{ mt: 2 }}>
                    Réessayer
                </Button>
            </Grid>
        );
    }

    return (
        <Grid container item rowSpacing={3} columnSpacing={2} xs={12}>
            <Grid container item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link sx={{ cursor: "pointer" }} underline="hover" color="inherit" onClick={() => history.push("/")}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Liste des produits</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                <Typography
                    sx={{
                        color: (theme) => theme.palette.primary.main,
                        borderRadius: 2,
                        fontWeight: 700,
                    }}
                    variant="h6"
                >
                    Liste des produits :
                </Typography>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems="center">
                    <TextField
                        label="Filtrer les produits"
                        variant="outlined"
                        size="small"
                        value={filter}
                        onChange={onFilterChange}
                        sx={{ width: { xs: "100%", sm: "300px" } }}
                    />
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body2">Par page :</Typography>
                        {[12, 24, 36].map((item) => (
                            <Chip
                                key={item}
                                disabled={pagination.perPage === item}
                                clickable
                                onClick={() => handlePerPageChange(item)}
                                label={item}
                                variant={pagination.perPage === item ? "filled" : "outlined"}
                                color="primary"
                                size="small"
                            />
                        ))}
                    </Stack>
                </Stack>
            </Grid>
            <Grid container columnSpacing={2} rowSpacing={4} item xs={12}>
                {loading ? (
                    Array.from(new Array(pagination.perPage)).map((_, index) => (
                        <ProductSkeleton key={index} />
                    ))
                ) : filteredProducts.length === 0 ? (
                    <Grid container item xs={12} justifyContent="center">
                        <Typography>Aucun produit trouvé</Typography>
                    </Grid>
                ) : (
                    paginatedProducts().map((product) => (
                        <Grid
                            container
                            rowSpacing={2}
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            key={product.id}
                            className={classes.card}
                        >
                            <Grid sx={{ height: 250 }} container item xs={12}>
                                <LazyLoadImage
                                    src={`${apiUrl}images/products/${product.images[0]?.image || "placeholder.jpg"}`}
                                    alt={product.name}
                                    width="100%"
                                    height="100%"
                                    effect="blur"
                                    placeholder={
                                        <img
                                            src="/logo.png"
                                            alt="Loading..."
                                            style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 4 }}
                                        />
                                    }
                                    style={{ objectFit: "cover", borderRadius: 4 }}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <Typography variant="body1" noWrap>
                                    {product.name}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid container item xs={6}>
                                    <Typography>
                                        <span className={classes.span}>Coût :</span> {product.cost} DT
                                    </Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Typography>
                                        <span className={classes.span}>Prix :</span> {product.price} DT
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Accordion sx={{ width: "100%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-${product.id}-content`}
                                        id={`panel-${product.id}-header`}
                                    >
                                        <Typography>Description :</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{product.description || "Aucune description"}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid container item xs={12}>
                                <Stack spacing={2} direction="row">
                                    <Button
                                        variant="outlined"
                                        onClick={() => history.push(routesName.products.edit(product.id))}
                                    >
                                        Editer
                                    </Button>
                                    <DeleteProduct setProducts={updateProductsList} product={product} />
                                </Stack>
                            </Grid>
                        </Grid>
                    ))
                )}
            </Grid>
            {!loading && filteredProducts.length > 0 && (
                <Grid container item xs={12} justifyContent="center" sx={{ mt: 2 }}>
                    <Pagination
                        count={pagination.totalPages}
                        page={pagination.currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        size="medium"
                    />
                    <Typography variant="body2" sx={{ ml: 2 }}>
                        {`Total: ${pagination.totalItems} produits`}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default ProductsList;